import React from 'react';
import { isNumber } from '../utils';
import { ReactComponent as Logo } from '../images/logo.svg';
import { ReactComponent as Redeemed } from '../images/redeemed.svg';

const Stamp = ({
  width,
  height,
  color,
  opacity,
  transform = {},
  redeemed = false,
  ...props
}) => {

  const Component = redeemed ? Redeemed : Logo;

  let { tx = 0, ty = 0, rotate = 0, scale = 1 } = transform;
  isNumber(tx) && (tx = `${tx}px`);
  isNumber(ty) && (ty = `${ty}px`);

  const style = {
    opacity,
    transform: `translate(${tx},${ty}) rotate(${rotate}deg) scale(${scale})`,
    pointerEvents: 'none'
  };

  return <Component className="stamp" width={width} height={height} color={color || 'currentColor'} style={style} {...props} />
};

export default React.memo(Stamp, (prevProps, nextProps) => (
  nextProps.redeemed === prevProps.redeemed
));