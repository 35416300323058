import React from 'react';

window.debug = process.env.NODE_ENV === 'development' ? (vars, styles = {}) => (
  <pre style={{maxWidth:'100%',fontSize:13,overflow:'scroll', ...styles}}>{JSON.stringify(vars, null, 2)}</pre>
) : vars => null;

export const noop = fn => fn;
export const isArray = Array.isArray;
export const toArray = Array.from;

export function isBoolean(value) {
  return typeof value === 'boolean';
};

export function toBoolean(value) {

  if (isBoolean(value)) {
    return value;
  }

  if (isString(value)) {
    switch (value.toLowerCase()) {
      case 'false':
      case 'no':
      case '0':
        return false;
      default:
        case 'true':
        case 'yes':
        case '1':
        return true;
    }
  }

  return Boolean(value);
};

export function isElement(value) {
  return !!(value && value.nodeType === 1);
};

export function isEmail(value) {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value); // eslint-disable-line
};

export function isEmpty(value) {
  if (isUndefined(value) || value === null || value === false || value === 0) {
    return true;
  }
  if (isArray(value) || isString(value)) {
    return value.length === 0;
  }
  if (isObject(value)) {
    return value.constructor === Object && Object.keys(value).length === 0;
  }
  return false;
};

export function isDefined(value) {
  return !isUndefined(value);
};

export function isFunction(value) {
  return typeof value === 'function';
};

export function isNumber(value) {
  return typeof value === 'number';
};

export function isNumeric(value) {
  if (isNumber(value)) return true;
  if (/^0x[0-9a-f]+$/i.test(value)) return true;
  return (/^[-+]?(?:\d+(?:\.\d*)?|\.\d+)(e[-+]?\d+)?$/).test(value);
};

export function isObject(value) {
  return typeof value === 'object';
};

export function isString(value) {
  return typeof value === 'string';
};

export function isUndefined(value) {
  return typeof value === 'undefined';
};

export function random(min, max) {

  if(arguments.length === 0) {
    return Math.random();
  }

  if(Array.isArray(min)) {
    return min[ Math.floor(Math.random() * min.length) ];
  }

  if(isUndefined(min)) min = 1;
  if(isUndefined(max)) max = min || 1, min = 0; // eslint-disable-line

  return min + Math.random() * (max - min);
};

export function shuffle(array) {
  const rand = () => 0.5 - random();
  return (arguments.length === 1) ? array.sort(rand) : rand();
};

export function wait(delay) {
  return new Promise(resolve => setTimeout(resolve, delay || 1));
};

export const lockScroll = (function() {

  const html = document.documentElement;
  const body = document.body;
  let isLocked = false;

  return () => {

    if (isLocked) return;
    isLocked = true;

    const scrollY = window.scrollY || window.pageYOffset || html.scrollTop || 0;
    const isDesktop = html.classList.contains('desktop');

    body.classList.add('no-overflow');
    if (!isDesktop) html.classList.add('no-overflow');

    return function unlock() {
      body.classList.remove('no-overflow');
      if (!isDesktop) html.classList.remove('no-overflow');
      window.scrollTo(0, scrollY);
      isLocked = false;
    };
  };
})();