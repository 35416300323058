import React from 'react';
import { css } from 'emotion';
import { colors } from '../styles';
import Stamp from './Stamp';
import withTransition from '../utils/transition';
// import Tween from '../utils/tween';

const styles = css`
  position: fixed;
  overflow: hidden;
  z-index: 100;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  background-color: ${colors.orange};
  background-image: linear-gradient(0, ${colors.orangeLight} 0%, ${colors.orangeDark} 100%);
  transition: all 0.4s;

  &.animate-out {
    opacity: 0;
    transform: scale(1.02);
  }
  svg {
    width: 33vw;
    max-width: 280px;
    min-width: 200px;
  }
`;

class Preloader extends React.Component {

  animateIn(node, done) {
    console.log('Preloader.animateIn');
    done();
  }

  animateOut(node, done) {
    // add min display time, aninated logo etc
    // node.classList.add('animate-out');
    // node.addEventListener('transitionend', done);
    done();
  }

  render() {
    return (
      <div className={styles}>
        <Stamp color="#FFF" />
      </div>
    );
  }
}

export default withTransition(Preloader);