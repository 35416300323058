import React, { useState } from 'react';
import { css, cx } from 'emotion';
import { colors } from '../styles';
import { random } from '../utils';
import Image from './Image';
import Stamp from './Stamp';
import Favorite from './Favorite';

const styles = css`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  font-size: 0.85rem;
  max-width: 100%;
  border-radius: 5px;
  background-color: #fff;
  box-shadow:
    0 0 0 1px rgba(${colors.rgb.brownDark},.01),
    0 1px 1px rgba(${colors.rgb.brownDark},.02),
    0 2px 8px rgba(${colors.rgb.brownDark},.08);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .card-image {
    display: block;
    position: relative;
    background-color: ${colors.bg};
  }
  .card-heading {
    font-size: 1.15em;
    line-height: 1.33;
    margin-bottom: 0.3em;
  }
  .card-content {
    padding: 1em;
    background-color: transparent;
  }
  .card-favorite {
    position: absolute;
    z-index: 5;
    top: 0;
    right: 0;
  }
  .card-tag {
    position: absolute;
    left: 0;
    top: 1em;
    z-index: 2;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    padding: 3px 8px 4px 8px;
    color: #FFF;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    border-radius: 0 2px 2px 0;

    &-eats {
      background-color: ${colors.eats};
    }
    &-drinks {
      background-color: ${colors.drinks};
    }
    &-shops {
      background-color: ${colors.shops};
    }
    &-experiences {
      background-color: ${colors.experiences};
    }
    &-lodging {
      background-color: ${colors.lodging};
    }
  }
  .stamp {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
    width: 55%;
    max-width: 120px;
    mix-blend-mode: multiply;
  }
  .stamp.is-redeemed {
    color: #E6402E;
  }

  .card-footer {
    margin: auto 6px 6px 6px;
    font-size: 11px;
    font-weight: 600;
    line-height: 1.15;
    text-align: center;
    padding: 5px;
    color: ${colors.brownLight};
    letter-spacing: 0.05em;
    text-transform: uppercase;
    background-color: rgba(${colors.rgb.tan},0.25);
    border-radius: 0 0 3px 3px;
  }
`;

export default ({coupon, tag, className, isPreview, ...props}) => {

  const [ stampStyles ] = useState({
    tx: random(-15, 15),
    ty: random(-15, 15),
    rotate: random(6, 12) * (random() < 0.5 ? -1 : 1),
    scale: random(0.98, 1.2),
    opacity: random(0.2,0.4)
  });

  if (isPreview) {
    coupon.limit = 1;
    coupon.redemptions = [];
  }

  const remaining = Math.max(0, coupon.limit - coupon.redemptions.length);
  const isRedeemed = coupon.limit !== 0 && remaining === 0;

  const stamp = !isRedeemed ? null : (
    <Stamp
      opacity={stampStyles.opacity}
      redeemed={true}
      transform={stampStyles}
      className={cx('stamp', coupon.redemptions.length >= coupon.limit && 'is-redeemed')}
    />
  );

  return (
    <article className={cx(styles, 'card', className)} {...props}>

      <Image
        className="card-image"
        src={coupon.photo}
        width={800}
        height={450}
        ratio={1}
      />

      <Favorite
        coupon={coupon}
        className="card-favorite"
      />

      {tag && <span className={cx('card-tag', `card-tag-${tag.toLowerCase()}`)}>{tag}</span>}

      <div className="card-content">
        <h1 className="card-heading">{coupon.offer}</h1>
        <p>{coupon.vendor.name}</p>
      </div>

      {stamp}

      {(isRedeemed || isPreview) ? null : (
        <div className="card-footer">
          {remaining !== coupon.limit ? `${remaining} Remaining` : coupon.limit === 0 ? 'Unlimited' : `Limit ${coupon.limit}`}
        </div>
      )}

    </article>
  )
};
