import React, { useState, useEffect } from 'react';
import { css, cx } from 'emotion';
import { actions, connect } from '../store';
import { colors, fonts, breakpoints } from '../styles';
import { isEmpty, random, lockScroll } from '../utils';
import { useToggle } from '../utils/hooks';
import NotFound from './NotFound';
import Button from '../components/Button';
import Icon from '../components/Icon';
import Image from '../components/Image';
import Stamp from '../components/Stamp';
import Favorite from '../components/Favorite';

const styles = css`
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.blueDark};
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;

  .coupon-wrap {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    background-color: ${colors.bg};
    background-image: url(${require('../images/topography-dark.svg')});
    background-position: center;
    background-size: 400px auto;
  }
  .coupon-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 5;
    left: 50%;
    top: 0;
    width: 100%;
    padding-top: 0.75em;
    transform: translateX(-50%);
  }
  .coupon-image {
    background-color: #202930;
  }
  .coupon-content {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    position: relative;
    padding-bottom: 100px;
  }
  .close {
    padding: 0.625em;
    color: #FFF;
    line-height: 0;
    border-radius: 2px;
    background-color: rgba(${colors.rgb.blueDark},0.4);

    svg {
      width: 14px;
      height: 14px;
    }
  }
  .favorite {
    transform: translateX(0.75em);

    svg {
      width: 26px;
      height: 26px;
    }
  }
  .stamp {
    width: 40%;
    max-width: 200px;
    color: #DDDDCF;
    position: absolute;
    z-index: 0;
    right: 0;
    top: 0;
    pointer-events: none;
    mix-blend-mode: multiply;

    &.is-redeemed {
      color: #E6402E;
      /* top: auto;
      bottom: 1em; */
    }
  }
  .coupon-header {
    position: relative;
    z-index: 1;
    margin-top: 1.5rem;

    h1 {
      font-size: 1.625rem;
      margin: 0;
      max-width: 85%;
    }
    h2 {
      font-family: ${fonts.body};
      font-weight: 400;
      font-size: 1.125rem;
      margin: 0.33em 0 0 0;
      color: ${colors.brownLight};
    }
  }
  .coupon-meta {
    position: relative;
    z-index: 1;
    margin: 1.5rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.85rem;
    line-height: 1;
    border: rgba(${colors.rgb.brownLight},0.3) 1px solid;
    border-left: 0;
    border-right: 0;

    li {
      flex: 1 0 auto;
      border-left: rgba(${colors.rgb.brownLight},0.3) 1px solid;
    }
    li:first-of-type {
      border-left: 0;
    }
    a {
      display: flex;
      justify-content: center;
      padding: 0.9em 1em;
    }
  }
  .coupon-cta {
    position: fixed;
    z-index: 10;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #FFF;
    box-shadow:
      0 -1px 2px 0 rgba(${colors.rgb.brownDark},0.05),
      0 0 8px 0 rgba(${colors.rgb.brownDark},0.05);

    .container {
      padding-top: 0.9rem;
      padding-bottom: 0.9rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .button {
      padding-left: 2.5em;
      padding-right: 2.5em;
    }
    small {
      font-size: 0.9rem;
      line-height: 1.5;
      text-align: right;
    }
    strong {
      padding-left: 3px;
      color: ${colors.brownDark};
    }
  }
  .coupon-redeem {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 100;
    width: 101%;
    height: 100%;
    left: 50%;
    bottom: 0;
    background-color: ${colors.orange};
    background-image: linear-gradient(0, ${colors.orangeLight} 0%, ${colors.orangeDark} 100%);
    visibility: hidden;
    transition-property: visibility, transform;
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0.75,0.05,0.15,1);
    transform: translate3d(-50%,100%,0);
    will-change: transform;

    &.is-active {
      visibility: visible;
      transform: translate3d(-50%,0,0);
    }
    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      height: 100%;
      margin: auto;
      padding: 2rem;
    }
    .container > :first-child,
    .container > :last-child {
      margin-top: auto;
    }
    h1 {
      color: #FFF;
      font-size: 2rem;
      font-weight: 700;
      line-height: 1.125;
      margin-bottom: 1.125em;
    }
    h2 {
      font-family: ${fonts.body};
      font-size: 1.125rem;
      margin-bottom: 0.5em;
      color: ${colors.red};
    }
    .button {
      max-width: 350px;
      margin-left: auto;
      margin-right: auto;
      padding: 1.5em 0.5em;
      border-color: #FFF;
    }
    .button.is-pending {
      color: ${colors.orange};
      background-color: #FFF;
    }
    .button.is-confirmed {
      color: #FFF;
      background-color: transparent;
    }

    small {
      display: block;
      margin: 1.25em 0;
      color: #FFF;
      opacity: 0.7;
    }
    a {
      color: #FFF;
    }
  }

  @media (min-width: 768px) {
    .coupon-content {
      margin-top: 1.5rem;
      margin-bottom: 3rem;
    }
    .coupon-image {
      padding-top: 66.67% !important;
    }
    .close svg {
      width: 18px;
      height: 18px;
    }
    .favorite svg {
      width: 32px;
      height: 32px;
    }
  }

  @media (min-width: 1080px) {
    .coupon-image {
      padding-top: 0 !important;
      height: 720px;
    }
  }
`;

const Coupon = ({coupon, today, className, ...props}) => {

  const [ stampStyles, setStampStyles ] = useState(getStampStyles(coupon && coupon.redeemed));
  const [ isRedeeming, toggleRedeeming ] = useToggle();

  const redeemedToday = coupon.redemptions.includes(today);
  const remaining = coupon.limit === 0 ? Number.POSITIVE_INFINITY : Math.max(0, coupon.limit - coupon.redemptions.length);

  useEffect(() => {
    const unlock = lockScroll();
    return unlock;
  }, []);

  if (isEmpty(coupon)) {
    return <NotFound />
  }

  function getStampStyles(redeemed) {
    return {
      tx: random(-15, 15),
      ty: random(-15, 15),
      rotate: random(6, 12) * (random() < 0.5 ? -1 : 1),
      scale: random(0.98, 1.25),
      opacity: redeemed ? random(0.2, 0.4) : random(0.5, 0.7)
    };
  }

  function onCloseClick(event) {
    props.history.goBack();
  }

  function onRedeemClick(event) {
    actions.redeem(coupon.id);
    setStampStyles(getStampStyles(coupon.redemptions.length >= coupon.limit));
  }

  const { vendor } = coupon;
  const address = isEmpty(vendor.address) ? null : encodeURI(`${vendor.address}, ${vendor.city}, ${vendor.state} ${vendor.zip}`);
  const expires = new Date(coupon.expires).toLocaleString('en-US', {timeZone: 'UTC', month: 'short', day: 'numeric', year: 'numeric'});

  const stamp = (
    <Stamp
      opacity={stampStyles.opacity}
      redeemed={!remaining}
      transform={stampStyles}
      className={cx('stamp', !remaining && 'is-redeemed')}
    />
  );

  return (
    <article className={styles}>
      <div className="coupon-wrap">

        <div className="coupon-top container">
          <button className="close" onClick={onCloseClick}>
            <Icon name="close" />
          </button>
          <Favorite
            className="coupon-favorite"
            coupon={coupon}
          />
        </div>

        <Image
          className="coupon-image"
          src={coupon.photo}
          width={window.innerWidth < breakpoints.lg ? 800 : 1600}
          height={window.innerWidth < breakpoints.lg ? 450 : 900}
          ratio={0.667}
        />

        <div className="coupon-content container">

          {stamp}

          <header className="coupon-header">
            <h1>{coupon.offer}</h1>
            <h2>{vendor.name}</h2>
          </header>

          <ul className="coupon-meta">
            {vendor.phone   && <li><a href={`tel:${vendor.phone.replace(/\D/, '')}`}>{vendor.phone}</a></li>}
            {vendor.website && <li><a href={vendor.website} rel="noopener noreferrer" target="_blank">Website</a></li>}
            {vendor.address && <li><a href={`https://www.google.com/maps/dir/?api=1&destination=${address}`} rel="noopener noreferrer" target="_blank">Directions</a></li>}
          </ul>

          <div dangerouslySetInnerHTML={{__html: coupon.description}} />

        </div>

        {remaining ? (
          <div className="coupon-cta">
            <div className="container">
              <Button className={redeemedToday && 'disabled w-100'} onClick={redeemedToday ? () => window.location.reload() : toggleRedeeming}>
                {redeemedToday ? 'Redeemed Today' : 'Redeem'}
              </Button>
              {redeemedToday ? null : <small>Expires <strong>{expires}</strong></small>}
            </div>
          </div>
        ) : null}

        <div className={cx('coupon-redeem', isRedeeming && 'is-active')}>
          <div className="container">
            <h2>{vendor.name}</h2>
            <h1>{coupon.offer}</h1>
            {redeemedToday ? (
              <React.Fragment>
                <Button className="w-100 is-confirmed">
                  Redeemed today at {(new Date()).toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true})}
                </Button>
                <small>&nbsp;</small>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Button onClick={onRedeemClick} className="w-100 is-pending">
                  Confirm Redemption
                </Button>
                <small>tap to redeem during checkout</small>
              </React.Fragment>
            )}
            {/* eslint-disable-next-line */}
            <a href="#" onClick={event => (event.preventDefault(), toggleRedeeming())}>
              {redeemedToday ? 'Close' : 'Cancel'}
            </a>
          </div>
        </div>

      </div>
    </article>
  );
};

export default connect((state, props) => ({
  coupon: state.coupons.find(c => c.id === Number(props.match.params.id)),
  today: state.today
}))(Coupon);
