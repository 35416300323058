import React from 'react';
import { createPortal } from 'react-dom';
import { css, cx } from 'emotion';
import { colors } from '../styles';
import { lockScroll, wait, noop } from '../utils';
import Icon from './Icon';
import withTransition from '../utils/transition';

const styles = css`
  position: fixed;
  z-index: 100;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;

  &.is-active {
    visibility: visible;
    opacity: 1;
  }
  .overlay {
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${colors.blueDark};
    opacity: 0.35;
  }
  .sidebar-outer {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${colors.bg};

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }
  .sidebar-inner {
    position: relative;
    z-index: 2;
    padding: 4.5rem 7vw;

    @media (min-width: 1500px) {
      padding-left: 100px;
      padding-right: 100px;
    }
  }
  .sidebar-close {
    position: absolute;
    z-index: 10;
    right: 2em;
    top: 1.75em;
    width: 20px;
    height: 20px;
    color: ${colors.brownLight};
  }
`;

const Close = props => (
  <button type="button" className="sidebar-close" onClick={props.onClick}>
    <Icon name="close" />
  </button>
);

class Sidebar extends React.Component {

  constructor(props) {
    super(props);
    this.unlock = noop;
  }

  componentWillUnmount() {
    this.unlock();
  }

  animateIn(node, done) {
    wait().then(() => (this.unlock = lockScroll()));
    done();
  }

  animateOut(node, done) {
    this.unlock();
    done();
  }

  render() {

    const { close, closeOnClick, width } = this.props;

    return createPortal(
      <div className={cx(styles, this.props.in && 'is-active')}>
        <div className="overlay" onClick={closeOnClick ? close : noop}></div>
        <section className="sidebar-outer" style={{maxWidth: width}}>
          <Close onClick={close} />
          <div className="sidebar-inner" ref={el => this.content = el}>
            {this.props.children}
          </div>
        </section>
      </div>,
      document.body
    );
  }
}

Sidebar.defaultProps = {
  close: noop,
  closeOnClick: true,
  width: 900
};

export default withTransition(Sidebar);