import React from 'react';
import { createPortal } from 'react-dom';
import { css } from 'emotion';
import { colors } from '../styles';
import { lockScroll, noop } from '../utils';
import Icon from './Icon';
import withTransition from '../utils/transition';

const styles = css`
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  z-index: 100;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;

  .overlay {
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${colors.blueDark};
    opacity: 0.35;
  }
  .modal-outer {
    display: table;
    width: 100%;
    height: 100%;
  }
  .modal-inner {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-dialog {
    position: relative;
    z-index: 1;
    margin: auto;
    padding: 4.5em 1.75rem 1.75rem 1.75rem;
    background-color: #FFF;
  }
  .modal-close {
    position: absolute;
    z-index: 1;
    right: 2em;
    top: 1.75em;
    width: 20px;
    height: 20px;
    color: ${colors.brownLight};
  }
`;

const Close = props => (
  <button type="button" className="modal-close" onClick={props.onClick}>
    <Icon name="close" />
  </button>
);

class Modal extends React.Component {

  constructor(props) {
    super(props);
    this.unlock = noop;
  }

  componentWillUnmount() {
    this.unlock();
  }

  animateIn(node, done) {
    this.unlock = lockScroll();
    done();
  }

  animateOut(node, done) {
    this.unlock();
    done();
  }

  render() {

    const { close, closeOnClick, width } = this.props;

    return createPortal(
      <div className={styles}>
        <div className="overlay" onClick={closeOnClick ? close : noop}></div>
        <section className="modal-outer">
          <div className="modal-inner">
            <div className="modal-dialog" style={{maxWidth: width}}>
              <Close onClick={close} />
              {this.props.children}
            </div>
          </div>
        </section>
      </div>,
      document.body
    );
  }
}

Modal.defaultProps = {
  close: noop,
  closeOnClick: true,
  width: 480
};

export default withTransition(Modal);