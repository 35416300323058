import React from 'react';
import { css, cx } from 'emotion';
import { isUndefined } from '../utils';
import { colors, fonts } from '../styles';

const styles = css`
  display: inline-block;
  padding: 1em 1.5em calc(1em + 1px) 1.5em;
  font-size: 0.85rem;
  font-family: ${fonts.body};
  font-weight: 700;
  line-height: 1;
  color: #FFF;
  border: 2px solid transparent;
  background-color: ${colors.orange};
  border: ${colors.orange} 2px solid;
  border-radius: 0;
  letter-spacing: 0.05em;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  user-select: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus,
  &:hover {
    color: #FFF;
    border-color: ${colors.orangeLight};
    background-color: ${colors.orangeLight};
  }
  &.disabled,
  &[disabled] {
    border-color: rgba(${colors.rgb.brownLight},0);
    background-color: rgba(${colors.rgb.brownLight},0.35);
  }
  &[disabled] {
    pointer-events: none;
  }

  &.inverted {
    color: ${colors.orange};
    border-color: #FFF;
    background-color: #FFF;

    &:focus,
    &:hover {
      color: #FFF;
      border-color: ${colors.orangeLight};
      background-color: ${colors.orangeLight};
    }
  }

  &.secondary {
    color: ${colors.orange};
    background-color: transparent;

    &:focus,
    &:hover {
      color: #FFF;
      border-color: ${colors.orangeLight};
      background-color: ${colors.orangeLight};
    }
    &[disabled] {
      border-color: rgba(${colors.rgb.brownLight},0.35);
      color: rgba(${colors.rgb.brownLight},0.35);
    }
  }
`;

const Button = ({
  as: Element = 'button',
  className = '',
  children,
  ...props
}) => {

  if (!isUndefined(props.href)) {
    Element = 'a';
  } else if (Element === 'button' && isUndefined(props.type)) {
    props.type = 'button';
  }

  return (
    <Element className={cx(styles, 'button', className)} {...props}>
      {children}
    </Element>
  );
};

export default Button;
