
function toRGB(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16)
  ] : null;
}

export const breakpoints = {
  xs: 375,
  sm: 600,
  md: 768,
  lg: 1024,
  xl: 1280
};

export const colors = {
  bg: '#FCFCFA',
  blue: '#007D9B',
  blueDark: '#202930',
  brownDark: '#3D2C11',
  brownLight: '#887F76',
  orange: '#F35500',
  orangeDark: '#D94100',
  orangeLight: '#FF6105',
  tan: '#D9D1C3',
  red: '#950F00',
  eats: '#E1591A',
  drinks: '#0C7B98',
  shops: '#6DBB0A',
  experiences: '#FFB400',
  lodging: '#193960',
};

colors.rgb = Object.entries(colors).reduce((result, [key, hex]) => {
  result[key] = toRGB(hex).join(',');
  return result;
}, {});

export const fonts = {
  body: 'Barlow, sans-serif',
  heading: 'Martel, Merriweather, serif'
};

// export const presets = {
//   gradient: {
//     background-color: ${colors.orange};
//     background-image: linear-gradient(0, ${colors.orange} 0%, ${colors.orangeDark} 100%);
//   }
// };

export default {
  breakpoints,
  colors,
  fonts
};