import React from 'react'
import { Transition } from 'react-transition-group'
import { isFunction } from './';

export default function withTransition(WrappedComponent) {

  return class extends React.Component {

    constructor(props) {
      super(props);
      this.handleTransition = this.handleTransition.bind(this);
    }

    handleTransition(node, done) {
      if (this.props.in) {
        this.animateIn(node, done);
      } else {
        this.animateOut(node, done);
      }
    }

    animateIn(node, done) {
      if (isFunction(this.wrappedComponent.animateIn)) {
        this.wrappedComponent.animateIn(node, done);
      } else {
        done();
      }
    }

    animateOut(node, done) {
      if (isFunction(this.wrappedComponent.animateOut)) {
        this.wrappedComponent.animateOut(node, done);
      } else {
        done();
      }
    }

    render() {
      return (
        <Transition {...this.props} addEndListener={this.handleTransition}>
          {state => <WrappedComponent {...this.props} ref={el => (this.wrappedComponent = el)} transitionState={state} />}
        </Transition>
      )
    }
  }
}