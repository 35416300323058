import React from 'react';
import { actions } from '../store';
import { isEmail, isEmpty, noop } from '../utils';
import Button from './Button';

class LoginForm extends React.Component {

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.email = React.createRef();

    this.state = {
      email: '',
      password: '',
      loading: false,
      error: null
    };
  }

  componentDidMount() {
    this.email.current.focus();
  }

  onChange(event) {
    const { name, value } = event.target;
    this.setState({[name]: value});
  }

  onSubmit(event) {
    event.preventDefault();
    const { email, password } = this.state;

    this.setState({
      loading: true,
      error: null
    });

    actions.login({email, password}).then(
      res => this.props.onLogin(),
      err => this.setState({loading: false, password: '', error: true})
    );
  }

  render() {

    const { error, loading } = this.state;
    const disabled = !isEmail(this.state.email) || isEmpty(this.state.password) || loading;

    return (
      <form onSubmit={this.onSubmit} autoComplete="off" noValidate={true}>

        {error && <p className="error mb-1">Invalid login information</p>}

        <div className="field">
          <label className="label hidden" htmlFor="login-email">Email</label>
          <input
            ref={this.email}
            id="login-email"
            type="email"
            name="email"
            value={this.state.email}
            className="input"
            placeholder="Email"
            onChange={this.onChange}
            required
          />
        </div>

        <div className="field">
          <label className="label hidden" htmlFor="login-password">Password</label>
          <input
            id="login-password"
            type="password"
            name="password"
            value={this.state.password}
            className="input"
            placeholder="Password"
            onChange={this.onChange}
            required
          />
        </div>

        <div className="text-center">
          <Button type="submit" className="w-100 mb-1" disabled={disabled}>
            {loading ? 'Loading Offers' : 'Start Exploring'}
          </Button>
          {/* eslint-disable-next-line */}
          <small><a href="#" onClick={e => (e.preventDefault(), this.props.showForgot())}>Forgot password</a></small>
        </div>

      </form>
    );
  }
}

LoginForm.defaultProps = {
  onLogin: noop
};

export default LoginForm;
