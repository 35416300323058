import React, { useEffect } from 'react';
import { css, cx } from 'emotion';
import { colors } from '../styles';
import { shuffle } from '../utils';
import { useToggle } from '../utils/hooks';
import data, { teasers, vendors } from '../data';
import Card from './Card';
import Stamp from './Stamp';
import Sidebar from './Sidebar';

const styles = css`
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 25vh;
    max-height: 400px;
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  }
  .container {
    max-width: 1080px;
    position: relative;
    z-index: 1;
  }
  .intro {
    h1 {
      max-width: 700px;
      margin-bottom: 0.25em;
    }
    strong {
      color: ${colors.blue};
    }
    p {
      max-width: 565px;
    }
  }
  .columns {
    position: relative;
    z-index: 2;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  .vendors-wrap ul,
  .vendors-wrap .stamp {
    display: none;
  }
  .vendors-header {
    margin: 1.5em 0;
  }
  .vendors-header h4 {
    margin-bottom: 0.25em;
  }


  @media (min-width: 580px) {

    .intro {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  @media (min-width: 768px) {
    padding-bottom: 5rem;

    .columns {
      margin-top: 4rem;
      margin-bottom: 3rem;
    }
    .card {
      box-shadow:
        0 15px 20px rgba(${colors.rgb.brownLight},.10),
        0  6px 8px  rgba(${colors.rgb.brownLight},.15),
        0 -6px 8px  rgba(${colors.rgb.brownLight},.02);

      &.large {
        box-shadow:
          0 30px 60px -12px rgba(${colors.rgb.brownDark},.25),
          0 18px 36px -18px rgba(${colors.rgb.brownDark},.30),
          0 -12px 36px -8px rgba(${colors.rgb.brownDark},.025);
        transform: scale(1.175);
        z-index: 2;
      }
    }
    .vendors {
      padding-left: 2rem;
      padding-right: 2rem;
      position: relative;

      &-wrap {
        display: block;
      }

      &-header {
        display: flex;
        align-items: flex-end;
        margin-bottom: 1.625em;
      }
      &-header h4 {
        font-size: 1.1rem;
        color: ${colors.brownDark};
        margin: 0 1em 0 0;
      }
      &-header a {
        font-size: 0.85em;
      }

      ul {
        max-width: 600px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 1em;
      }
      li {
        width: 48%;

        a {
          font-size: 0.85em;
          font-weight: 400;
          color: currentColor;

          &:hover {
            color: ${colors.orange};
          }
        }
      }
      .stamp {
        display: block;
        position: absolute;
        z-index: 0;
        right: 0;
        top: 0;
        width: 33vw;
        max-width: 320px;
        color: ${colors.tan};
        mix-blend-mode: multiply;
      }
    }
  }

  @media (min-width: 1024px) {

    .intro {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      h1 {
        width: 47%;
        margin-bottom: 0;
      }
      p {
        width: 45%;
      }
    }
    .vendors {
      ul {
        max-width: 900px;
      }
      li {
        width: 33%;
      }
    }
  }
`;

export default props => {

  const [
    isOffersActive,
    toggleOffers
  ] = useToggle(window.location.pathname === '/offers');

  useEffect(() => {
    const isDeepLink = window.location.pathname === '/offers';
    if (isDeepLink && !isOffersActive) window.history.replaceState({}, '', '/');
  }, [isOffersActive]);

  shuffle(teasers);
  const coupons = shuffle(teasers).slice(0, 4);

  return (
    <article className={styles}>
      <div className="container">

        <header className="intro">
          <h1>A Preview of Savings with Your <strong>Discovery Pass</strong></h1>
          <p>Over $800 in savings with your Discovery Pass, valid through October 31, 2023. <a href="#" onClick={e => (e.preventDefault(), toggleOffers())}>Click here</a> to view all freebies and savings.</p>{/* eslint-disable-line */}
        </header>

        <div className="columns">
          {coupons.map((coupon, i) =>
            <Card key={i} coupon={coupon} isPreview={true} className={cx('col-6 sm:col-3 cursor-default', i === 2 && 'large')} />
          )}
        </div>

        <div className="vendors">
          <div className="vendors-wrap">
            <div className="vendors-header">
              <h4>Featuring {vendors.length} Local Businesses</h4>
              <a href="#" onClick={e => (e.preventDefault(), toggleOffers())}>view all freebies and savings</a>{/* eslint-disable-line */}
            </div>
            <ul>
              {vendors.map((vendor, i) =>
                <li key={i}>
                  <a href={vendor.website} rel="noopener noreferrer" target="_blank">{vendor.name}</a>
                </li>
              )}
            </ul>
            <Stamp opacity={0.3} transform={{tx: '25%', ty: '-35%', rotate: -11}} />
          </div>
        </div>

        <Sidebar in={isOffersActive} mountOnEnter={true} unmountOnExit={true} close={toggleOffers}>
          <div className="content">
            <h1>Discovery Pass Savings</h1>
            <p className="lead">{data.length} offers from business in Salida, Buena Vista and the surrounding area. All offers valid through October 31, 2023.</p>
            {data.map((item, i) =>
              <div key={i} style={{marginBottom: '1em'}}>
                <h5>{item.vendor.name}</h5>
                <p style={{fontSize: '0.9em', margin: 0}}>{item.offer}</p>
                {item.disclaimer && <small style={{display: 'block', opacity: 0.6}}> * blackout dates apply</small>}
              </div>
            )}
            <a href="#" onClick={e => (e.preventDefault(), toggleOffers())}>Return to site</a>{/* eslint-disable-line */}
          </div>
        </Sidebar>

      </div>
    </article>
  );
};
