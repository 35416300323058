import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../components/Button';

const Landing = (props) => {

  return (
    <section className="container">
      <div className="text-center my-4 px-1">
        <h1 className="mb-1">Get Your Annual Discovery Pass</h1>
        <p>The 2023 Discovery Pass is here with a new set of offers, available through October 31, 2023!</p>
        <Button as={Link} to="/buy">Buy the New Pass</Button>
      </div>
    </section>
  );
};

export default Landing;
