import React from 'react';
import { css, cx } from 'emotion';
import { isUndefined, isString } from '../utils';

const styles = css`
  display: block;
  position: relative;
  overflow: hidden;

  img {
    display: block;
    height: auto;
    width: 100%;
  }
  &.has-ratio img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .no-object-fit & img {
    width: auto;
    height: auto;
    max-width: none;
    min-width: 100%;
    min-height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
`;

export default React.memo(({
  src,
  alt,
  width,
  height = width,
  ratio,
  style = {},
  className,
  ...props
}) => {

  ratio && Object.assign(style, {paddingTop: `${ratio * 100}%`});

  if (isString(src) && !src.startsWith('http')) {
    src = `https://api.discoverypass.co/storage/${src}`;
    src = isUndefined(width) ? src : `https://res.cloudinary.com/nikrowell/image/fetch/w_${width},h_${height},c_fill,f_auto,q_auto:best/${src}`;
  }

  return (
    <figure className={cx(styles, 'image', ratio && 'has-ratio', className)} style={style} {...props}>
      <img src={src} alt={alt || ''} />
    </figure>
  );
});