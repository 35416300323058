import React from 'react';
import { css } from 'emotion';
import { colors, fonts } from '../styles';
import data, { vendors } from '../data';

const styles = css`
  background-color: #fff;
  padding-top: 5rem;
  padding-bottom: 5rem;

  .container {
    position: relative;
    max-width: 1280px;
  }
  .callout {
    text-align: center;

    &:not(:first-of-type) {
      margin-top: 1rem;
    }
  }
  h3 {
    margin-bottom: 0.75em;
    font-weight: 700;
    color: ${colors.orangeDark};
  }
  strong {
    display: block;
    font-size: 3.5rem;
    font-family: ${fonts.body};
    font-weight: 700;
    color: ${colors.brownDark};
  }
  br {
    display: none;
  }
  p {
    font-size: 1.125rem;
    max-width: 380px;
    margin: 0 auto;
  }

  @media (min-width: 768px) {

    .container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 1px;
        height: 2.5rem;
        background-color: #D8D8D8;
        font-size: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      &:before {
        left: 33.33%;
      }
      &:after {
        left: 66.67%;
      }
    }
    .callout {
      flex: 1 0 30%;

      &:not(:first-of-type) {
        margin-top: 0;
      }
    }
    strong {
      font-size: 3rem;
    }
    br {
      display: initial;
    }
    p {
      font-size: 0.9rem;
      max-width: 100%;
      padding-left: 15%;
      padding-right: 15%;
    }
  }
`;

export default props => (
  <section className={styles}>
    <div className="container container-lg">
      <div className="callout">
        <h3><strong>{data.length}</strong> Unique <br />Offers</h3>
        <p>Eats, drinks, shops, lodging and experiences at {vendors.length} local businesses</p>
      </div>
      <div className="callout">
        <h3><strong>$800</strong> In Savings &amp; <br />Freebies</h3>
        <p>Offers valid November 1, 2022 through October 31, 2023</p>
      </div>
      <div className="callout">
        <h3><strong>$25</strong> Discovery <br />Pass</h3>
        <p>Start using it immediately on your smartphone. No apps to install.</p>
      </div>
    </div>
  </section>
);
