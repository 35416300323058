import React from 'react';
import { css } from 'emotion';
import { useToggle } from '../utils/hooks';
import { colors } from '../styles';
import Social from './Social';
import Sidebar from './Sidebar';

const styles = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  padding-top: 2rem;
  padding-bottom: 1.25rem;

  .is-app & li:last-child { /* social */
    display: none;
  }

  .container {
    max-width: 1280px;
  }
  a {
    font-weight: 500;
    color: currentColor;
  }
  a:hover {
    color: ${colors.orange};
  }
  li {
    margin-bottom: 0.33em;

    &:last-child { /* social */
      margin-top: 1.5em;
      margin-bottom: 0;
    }
  }
  .social a {
    margin-right: 1em;
  }
  .credits {
    font-size: 13px;
    font-weight: 400;
    margin-top: 2rem;
    text-align: center;
    opacity: 0.8;

    a {
      font-weight: 400;
    }
  }

  @media (min-width: 768px) {

    ul {
      text-align: center;
    }
    li {
      display: inline-block;
      margin-top: 0.25em;
      margin-right: 1.25em;

      &:last-child { /* social */
        display: block;
        margin: 1.75em 0 0.75em 0;
      }
    }
    .social {
      display: flex;
      justify-content: center;

      a {
        margin: 0 0.5em;
      }
    }
  }

  @media (min-width: 1024px) {
    font-weight: 600;
    padding-bottom: 3rem;

    a {
      font-weight: 600;
    }
    ul {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      border: rgba(${colors.rgb.brownLight},0.3) 1px solid;
      border-left: 0;
      border-right: 0;
    }
    li {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      letter-spacing: 0.02em;
      margin: 0;
      padding: 0.75rem 0.5em;
      text-align: center;
      text-transform: uppercase;
      border-left: rgba(${colors.rgb.brownLight},0.3) 1px solid;

      &:first-of-type {
        border-left: 0;
      }
      &:last-child { /* social */
        margin: 0;
        min-width: 130px;
        max-width: 180px;
      }
    }
    .social {
      display: flex;
      justify-content: space-around;
      margin: 0;
      padding-left: 5px;
      padding-right: 5px;

      a {
        margin: 0;
      }
    }
  }
`;

export default props => {

  const [ isPrivacyActive, togglePrivacy ] = useToggle(false);
  const [ isTermsActive, toggleTerms ] = useToggle(false);

  return (
    <footer className={styles}>
      <div className="container">

        <ul>
          <li className="copyright">&copy; {new Date().getFullYear()} Discovery Pass LLC. All Rights Reserved</li>
          <li className="legal"><a href="#" onClick={e => (e.preventDefault(), togglePrivacy())}>Privacy Policy</a></li>{/* eslint-disable-line */}
          <li className="legal"><a href="#" onClick={e => (e.preventDefault(), toggleTerms())}>Terms of Use</a></li>{/* eslint-disable-line */}
          <li><a href="mailto:info@discoverypass.co">info@discoverypass.co</a></li>
          <li><Social /></li>
        </ul>

        <span className="credits hide lg:show">Designed and Developed by <a href="http://www.nikrowell.com" rel="noopener noreferrer" target="_blank">Nik Rowell</a> in Salida, CO</span>

        <Sidebar in={isPrivacyActive} mountOnEnter={true} unmountOnExit={true} close={togglePrivacy}>
          <div className="content">
            <h1>Privacy Policy</h1>
            <p className="lead">This Privacy Policy describes how we treat the information we collect when you visit our Website and/or register to be part of Discovery Pass. Please read this notice carefully and let us know if you have any questions.</p>
            <p>This Privacy Policy applies to all of the products, services and websites offered by The Discovery Pass LLC or its subsidiaries or affiliated companies (collectively, Discovery Pass "services"). It is designed to help you understand how we collect and use the personal information you decide to share. By using or accessing <a href="https://www.discoverypass.co/">www.discoverypass.co</a>, you are accepting the practices described in this Privacy Policy.</p>
            <p>Please note that this Privacy Policy may change from time to time. We will post any Privacy Policy changes on this page.</p>
            <h3>How We Use Your Email Address</h3>
            <p>When you purchase a Discovery Pass and/or create an account you will be required to provide a valid email address. We will only use this email address to contact you as part of the transaction process, to send you periodic emails that you have subscribed to, or for purposes that are intended to enhance your user experience. We may also occasionally contact you to obtain feedback on a specific merchant deal you have participated in. We will not share your contact information with third parties.</p>
            <h3>Opt-out</h3>
            <p>Discovery Pass will generally allow you to choose to not receive certain types of email communication from us, except for those that are absolutely necessary. Emails will generally contain a link at the bottom to be removed from our mailing list.</p>
            <h3>How We Use Your Name</h3>
            <p>When purchasing a Discovery Pass, you will be required to provide the name of the person who will use it, whether that will be you or the person who will receive the Pass as a gift. We will also collect your name during the checkout process to verify your credit card payment info. </p>
            <h3>How We Use Your Financial Information</h3>
            <p>For purchasing the Discovery Pass, we request credit card and other payment account information which are maintained in encrypted form on secure servers. We do not share this information with any third party except for those companies responsible for processing credit card transactions for us.</p>
            <h3>How We Use Your Demographic Information</h3>
            <p>We will occasionally collect demographic data, such as zip code, date of birth and gender. Providing this information is typically optional but helps us bring you the best deals possible. We use this data to help determine which deals our users would most appreciate. We may also share this data with third parties, such as merchants featured on our site, but only in aggregate. No third party will be able to trace your personal demographic information back to you unless you have given your permission to do so.</p>
            <h3>Cookies, Web Beacons and Unique URLs</h3>
            <p>Like most sites, Discovery Pass makes use of browser "cookies." Cookies are small text files placed on your computer's hard disk by our server. They allow us to quickly check your status as a Pass holder and facilitate access to your preferences and offers. Discovery Pass will never use cookies to retrieve information from a computer that is unrelated to our site or services.</p>
            <h3>Your IP Address</h3>
            <p>We may occasionally collect and store your IP address. This helps us diagnose problems with our servers, administer our site, gather non-personal information about our users, and detect and prevent fraudulent activity. It does not identify you personally in any way.</p>
            <h3>Minors</h3>
            <p>Discovery Pass does not knowingly collect any information from persons between the ages of 13-17. If we determine that any content has been displayed on our site by someone under the age of 13, it will be removed. If you are under the age of 21, we request that you please leave the site.</p>
            <h3>Our Security Precautions</h3>
            <p>We take security concerns very seriously. We use secure server software, known as an SSL certificate, to ensure that sensitive personal and financial information is encrypted.</p>
            <h3>Links To Other Websites</h3>
            <p><a href="https://www.discoverypass.co/">www.discoverypass.co</a> may contain links to other websites. Discovery Pass is not responsible for the privacy practices of third parties or the content of these websites.</p>
            <a href="#" onClick={e => (e.preventDefault(), togglePrivacy())}>Return to site</a>{/* eslint-disable-line */}
          </div>
        </Sidebar>

        <Sidebar in={isTermsActive} mountOnEnter={true} unmountOnExit={true} close={toggleTerms}>
          <div className="content">
            <h1>Terms of Use</h1>
            <p className="lead">Thank you for visiting www.discoverypass.co ("Discovery Pass" or the "Site"), a website owned and operated by Discovery Pass LLC ("Discovery Pass"). These Terms of Service ("Terms") incorporate the Privacy Policy and describe the rules that must be obeyed by any person who establishes a connection for access to and use of the Site ("User").</p>
            <h3>Accepting the Terms</h3>
            <p>By using or visiting the Site, you expressly agree to be bound by these Terms and to follow these Terms and all applicable laws and regulations governing the Site. You may not use the Services if you do not agree to the Terms.</p>
            <h3>Changes to the Terms of Use</h3>
            <p>Discovery Pass has the right to modify or discontinue any feature or aspect of the Site at any time. Discovery Pass also has the right to change the Terms governing any User's use of this Site at any time. The change in the Terms will be effective immediately upon posting the changes to this Terms page or elsewhere on the Site. Any use subsequent to these changes shall be deemed to constitute acceptance by the User of such modifications, additions, or deletions. If you do not agree with the modified Terms, your only remedy is to discontinue using the Site.</p>
            <h3>Definitions</h3>
            <p>Discovery Pass Site provides an interactive online service operated by Discovery Pass LLC. (herein referred to as "Discovery Pass") on the World Wide Web of the Internet (the "Web"), consisting of information services, content and transaction capabilities provided by Discovery Pass, affiliates of Discovery Pass and other third parties.</p>
            <h3>User Passwords and Account Security</h3>
            <p>You agree and understand that you are responsible for maintaining the confidentiality of passwords associated with your account. You agree that you will be solely responsible for all activities that occur under your account. Although Discovery Pass will not be liable for your losses caused by any unauthorized use of your account, you may be liable for the losses of Discovery Pass or others due to such unauthorized use. If you become aware of any unauthorized use of your password or of your account, you agree to notify Discovery Pass immediately at <a href="mailto:info@discoverypass.co">info@discoverypass.co</a>.</p>
            <h3>Offer Purchase and Redemption</h3>
            <p>Redemption of merchant offers is subject to the limitations and rules set by the respective merchants, and such merchant certificates may not be available for use and redemption by individuals located outside the United States.</p>
            <h3>Electronic Communications</h3>
            <p>Discovery Pass will generally communicate with its Users by electronic means, such as e-mail. As a User, you give consent that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.</p>
            <h3>Privacy</h3>
            <p>Privacy is something we take very seriously at Discovery Pass LLC. Please see our full privacy policy for details.</p>
            <h3>Linked Sites</h3>
            <p>The Web Site and Electronic Communication may contain certain links to third parties' Web Sites ("Linked Sites") from the Discovery Pass Web Site. Linked Sites are not, however, reviewed, controlled, or examined by Discovery Pass in any way and Discovery Pass is not responsible for the content, availability, advertising, products or other materials of any such Linked Sites, or any additional links contained therein. These links do not imply Discovery Pass's endorsement of or association with the Linked Sites. It is your sole responsibility to comply with the appropriate terms of service of the Linked Sites as well as with any other obligation under copyright, secrecy, defamation, decency, privacy, security and export laws related to the use of such Linked Sites and any content contained thereon. In no event shall Discovery Pass be liable, directly or indirectly, to anyone for any loss or damage arising from or occasioned by the creation or use of the Linked Sites or the information or material accessed-through these Linked Sites. You should direct any concerns to that site's administrator or webmaster. Discovery Pass reserves the exclusive right, at its sole discretion, to add, change, decline or remove, without notice, any feature or link to any of the Linked Sites from the Web Site and/or introduce different features or links to different users.</p>
            <p>Linked sites may contain content and graphics that may be offensive. If you enter these linked sites, you assert that you are of legal adult age in your jurisdiction to view such materials and that the viewing, reading, and/or downloading of content from these linked sites does not violate the community standards of your locality, city, town, county, state, province, country or other community to which you belong, and/or from which you access these linked sites.</p>
            <p>Permission must be granted by Discovery Pass for any type of link to the Web Site or any Discovery Pass Content. To seek permission, you may write to Discovery Pass. We reserve the right, however, to rescind any permission granted by us to link through any type of link, and to require termination of any such link to the Web Site, at our discretion at any time.</p>
            <h3>Contests, Simulations or Games</h3>
            <p>From time to time, Discovery Pass may offer contests, simulations or games (collectively, "Contests"). To participate in Contests, users may be required to complete a registration form. Upon submission, all registrations become the exclusive property of Discovery Pass. Registered users are permitted only one account. Registered users with more than one account are subject to immediate disqualification from any Contest, unless the description of the Contest expressly invites a user to register multiple times. Discovery Pass, at its sole discretion, may suspend or revoke the registration of any registered user.</p>
            <p>Registered users agree to release Discovery Pass and its agents, advertisers, sponsors or promotional partners, from all liability arising from participation in any of Contest located on, or accessed through, the Web Site. Discovery Pass is not responsible for technical, hardware or software failures of any kind, lost or unavailable network connections, or failed, incomplete, garbled or delayed computer transmissions which may limit any registered user's ability to participate in any Contest.</p>
            <p>Each registered user agrees to be bound by the rules of any Contest and by the rules of fair play and consideration of fellow registered users. A registered user who violates any of the rules or guidelines for behavior published on the Web Site, or the site where the Contest is conducted, is subject to immediate disqualification and revocation of their registration. Discovery Pass is under no obligation to award any prize to any registered user who violates a published rule or guideline.</p>
            <p>Some Contest may offer prizes to registered users. All prizes are subject to the official game or contest rules published with respect to that Contest.</p>
            <p>Discovery Pass reserves the right to cancel, terminate or alter any Promotion or the rules thereof at any time without prior notification.</p>
            <h3>Alcoholic Beverages</h3>
            <p>All alcoholic products offered on <a href="https://www.discoverypass.co/">www.discoverypass.co</a> are sold by a third party licensed seller which we select, and which is the seller of record. Absolutely no alcohol product can be sold to persons under the age of 21 and the common carrier which delivers the alcohol merchandise ordered will require age verification upon delivery. By purchasing a Pass using the Web Site you agree that you are at least 21 years of age and that the alcohol you order is intended for personal consumption only and will not be resold.</p>
            <h3>Terms of Sale</h3>
            <p>When purchasing a Discovery Pass, you make an offer to us to purchase the Pass on the terms and conditions stated below. For more information, please view our privacy policy.</p>
            <p>You are required to create an account in order to purchase any Pass. This is required to ensure permissible use of the product.</p>
            <p>The Pass you purchase is redeemable for goods or services by the seller of such goods and services, hereinafter defined as "Merchant." The Merchant, not Discovery Pass, is the seller of the goods and services and is solely responsible for redeeming the promotion you purchase. Discovery Pass sells a promotion that can be redeemed in connection with your purchase of the goods or services from Merchant.</p>
            <h3>Lawful Use</h3>
            <p>User shall not use this site for any unlawful purpose. Strictly prohibited actions include transmitting material: (i) that is unlawful, obscene, vulgar, defamatory, abusive, threatening, profane, invasive of privacy or publicity rights, or otherwise objectionable, (ii) that violates or infringes upon the rights of others in any way, (iii) that encourages others to commit unlawful acts, (iv) that contains advertising, publicity, or solicitation for a product or services that did not receive prior approval from Discovery Pass, (v) that inhibits other users from using or enjoying the Site.</p>
            <h3>Property of Discovery Pass</h3>
            <p>All Content and software on the Site is the property of Discovery Pass, affiliated companies, partners, or content suppliers. This includes, but is not limited to, all text, images, graphics, logos, and software code. You may not modify, rent, lease, loan, sell, distribute or create derivative works based on this Content (either in whole or in part) unless you have received specific permission to do so in a separate agreement from Discovery Pass or by the owners of the Content. Unauthorized use of any content displayed by Discovery Pass is a violation of United States and international copyright laws.</p>
            <h3>Disclaimers and Limitation of Liability</h3>
            <p>Discovery Pass makes no representations or warranties of any kind, express or implied, as to the operation of the Site or to the content, information, products, or services featured on the Site. We provide this Site on an "as is" and "as available" basis.</p>
            <p>User agrees to use this site at User's own risk. User expressly understands and agrees that Discovery Pass, its employees, officers, shareholders, contractors, agents, affiliated parties, or any person or entity involved in creating, producing, posting or distributing Discovery Pass content will under no circumstances be liable for damages, including, without limitation, direct, indirect, incidental, punitive, or consequential damages arising out of the use or inability to use the Site, however caused and under any theory of liability.</p>
            <p>To the full extent permissible by applicable law, Discovery Pass disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability, accuracy, non-infringement, and fitness for any particular purpose. Discovery Pass disclaims any and all liability for the acts, omissions, and conduct of any third party service providers, partners, sponsors, licensors, licensees, and the like in connection with or related to the use of the Site and materials, offers, features, and services made available therein. Discovery Pass does not warrant that this site, servers, or emails sent from the Site are free of harmful components, such as viruses.</p>
            <p>Neither Discovery Pass, nor any third parties providing content or services shall be liable regardless of the cause or duration, for any errors, inaccuracies, omissions or other defects in, or untimeliness or inauthenticity of, the information contained on this site, or for any delay or interruption in the transmission thereof to the user, or for any claims or losses arising from this. None of the foregoing parties shall be liable for any third-party claims or losses of any nature, including, but not limited to, lost profits, punitive or consequential damages. Because some states do not permit the exclusion or limitation of certain damages, in such jurisdictions, liability is limited to the fullest extent permitted by such state law.</p>
            <h3>Indemnification</h3>
            <p>User agrees to indemnify and hold Discovery Pass, its affiliates, officers, shareholders, agents and other partners and employees harmless from and against any and all claims, damages, losses, costs (including reasonable attorneys' fees), and expenses that arise directly or indirectly out of or from: (1) User's breach of these Terms; and/or (2) User's activities in connection with the Site and/or materials, offers, features, and services made available therein.</p>
            <h3>Governing Law and Jurisdiction</h3>
            <p>These Terms are governed by the United States law and are subject to all applicable federal, state, and local laws and regulations. All issues and questions concerning the construction, validity, interpretation, and enforceability of these Terms, or the rights and obligations of User and Discovery Pass in connection with the use of the Site, shall be governed by, and construed in accordance with, the laws of the State of Colorado, without giving effect to the conflict of laws rules thereof, and any matters or proceedings, shall take place in the State of Colorado, in Chaffee County.</p>
            <h3>Miscellaneous</h3>
            <p>The failure of Discovery Pass to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision.</p>
            <p>The failure of Discovery Pass to comply with these Terms because of an act of God, war, fire, riot, terrorism, earthquake, actions of federal, state, or local governmental authorities, or for any other reason beyond the reasonable control of Discovery Pass shall not be deemed a breach of this agreement. If Discovery Pass fails to act with respect to a breach on any occasion, Discovery Pass is not waiving its right to act with respect to future or similar breaches.</p>
            <p>The headings in these Terms are for convenience and reference. These headings do not limit or affect this agreement.</p>
            <p>If any provision of these Terms shall be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from these Terms and shall not affect the validity and enforceability of any remaining provisions.</p>
            <h3>Binding Agreement</h3>
            <p>These Terms constitute a binding agreement between the User and Discovery Pass, and are accepted by the User upon use of the Site. This agreement constitutes the entire agreement between User and Discovery Pass regarding the use of the Site and the features therein. By using this Site, you represent that you are capable of entering into a binding agreement, and that you agree to be bound by these Terms.</p>
            <a href="#" onClick={e => (e.preventDefault(), toggleTerms())}>Return to site</a>{/* eslint-disable-line */}
          </div>
        </Sidebar>

      </div>
    </footer>
  );
};