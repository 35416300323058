import device from 'current-device'; // eslint-disable-line

export const polyfills = [];
const classList = document.documentElement.classList;

/* export default Object.assign({}, bowser, {
  // classes: getClasses(),
  // isMobile: browser.mobile || browser.tablet,
  // isPhone: browser.mobile,
  // isTablet: browser.tablet,
  // isDesktop: !browser.mobile && !browser.tablet,
  // isChrome: browser.chrome,
  // isIE: browser.msie,
  // isEdge: browser.msedge,
  // isFirefox: browser.firefox,
  get orientation() {

    if (window.screen) {
      const orientation = window.screen.orientation || window.screen.mozOrientation || window.screen.msOrientation;
      if (orientation && orientation.type) {
        return orientation.type.split('-', 1)[0];
      }
    }

    const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    return w > h ? 'landscape' : 'portrait';
  }
}); */

// https://polyfill.io/v3/url-builder/
('fetch' in window)              || polyfills.push('fetch');
('Promise' in window)            || polyfills.push('Promise');
('URLSearchParams' in window)    || polyfills.push('URLSearchParams');
('assign' in Object)             || polyfills.push('Object.assign');
('entries' in Object)            || polyfills.push('Object.entries');
('from' in Array)                || polyfills.push('Array.from');
('find' in Array.prototype)      || polyfills.push('Array.prototype.find');
('includes' in Array.prototype)  || polyfills.push('Array.prototype.includes');
('closest' in Element.prototype) || polyfills.push('Element.prototype.closest');
('matches' in Element.prototype) || polyfills.push('Element.prototype.matches');

// if (!('IntersectionObserver' in window) ||
//     !('IntersectionObserverEntry' in window) ||
//     !('isIntersecting' in window.IntersectionObserverEntry.prototype)) {
//   polyfills.push('IntersectionObserver', 'IntersectionObserverEntry');
// }

if (typeof document.body.style.grid !== 'string') {
  classList.add('no-grid');
}

if ('objectFit' in document.documentElement.style === false) {
  classList.add('no-object-fit');
}