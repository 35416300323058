import React from 'react';
import { css } from 'emotion';
import { Link } from 'react-router-dom';
import { connect } from '../store';
import { colors } from '../styles';
import { random } from '../utils';
import { useToggle } from '../utils/hooks';
import Button from './Button';
import Stamp from './Stamp';
import Icon from './Icon';
import Nav from './Nav';

const styles = css`

  .banner {
    display: none;
  }
  .is-home & .banner {
    display: block;
    font-size: 15px;
    padding: 0.5em;
    color: #FFF;
    text-align: center;
    background-color: ${colors.orangeLight};
  }

  .container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo {
    line-height: 0;
    color: #DDDDCF;
  }
  .nav-toggle {
    padding: 1.2rem;
    transform: translateX(0.75rem);
    color: ${colors.orange};
    line-height: 0;

    svg {
      width: 1.4rem;
      height: 1.4rem;
    }
  }

  .is-home & {
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;

    .container {
      max-width: 1600px;
    }
    .logo {
      width: 27vw;
      max-width: 160px;
      min-width: 125px;
      position: relative;
      left: -2.5%;
      margin-top: 1.125rem;
      margin-bottom: 1.125rem;
      color: ${colors.bg};
      opacity: 0.85;

      &-rings {
        display: none;
      }
    }
    .nav-toggle {
      color: #FFF;
      border: rgba(255,255,255,0.5) 1px solid;
      transform: none;
    }
    .buttons {
      position: relative;
      z-index: 1;
    }
    .button {
      margin-left: 1rem;
    }
    .button-order {
      display: none;
    }
    .button-login {
      color: #FFF;
      border-color: #FFF;
      background-color: rgba(${colors.rgb.blueDark},0.05);
      text-shadow: 0 0 25px rgba(${colors.rgb.blueDark},0.25);

      &:hover {
        color: #FFF;
        border-color: ${colors.orange};
        background-color: ${colors.orange};
      }
    }

    @media (min-width: 600px) {

      .button-order {
        display: inline-block;
      }
    }

    @media (min-width: 768px) {

      .container {
        padding: 0 6vw;
      }
      .button {
        width: 135px;
      }
    }

    @media (min-width: 1080px) {

      .logo {
        max-width: 200px;
      }
    }
  }

  .is-app & {
    position: relative;
    padding-top: 1rem;

    .logo {
      width: 33vw;
      min-width: 100px;
      max-width: 130px;
      position: relative;
    }
  }
`;

const Header = props => {

  const {
    toggleLogin,
    toggleOrder,
  } = props;

  const [
    isNavActive,
    toggleNav
  ] = useToggle();

  return (
    <header className={styles}>
      {/* <a href="https://gift.discoverypass.co/" className="banner">
        Give Discovery Pass as a gift! <strong>Click Here</strong>
      </a> */}
      <div className="container">
        <Link to="/" className="logo">
          <Stamp transform={{rotate: random(-10,-5)}} />
        </Link>
        {props.isAuthed ? (
          <div>
            <Nav in={isNavActive} close={toggleNav} />
            <button className="nav-toggle" onClick={toggleNav}>
              <Icon name="menu" />
            </button>
          </div>
        ) : (
          <div className="buttons">
            <Button className="button-login" onClick={e => { e.target.blur(); toggleLogin()}}>Log in</Button>
            <Button className="button-order" onClick={e => { e.target.blur(); toggleOrder()}}>Buy Now</Button>
          </div>
        )}
      </div>
    </header>
  );
};

export default connect(state => ({
  isAuthed: state.isAuthed
}))(Header);
