import React from 'react';
import { css, cx } from 'emotion';
import { Link, withRouter } from 'react-router-dom';
import { connect } from '../store';
import { breakpoints } from '../styles';
import Card from '../components/Card';

// TODO: refactor to not duplicate this code with Category.js

const styles = css`
  margin-top: 2rem;
  margin-bottom: 2rem;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  header h2 {
    font-size: 1.25rem;
    line-height: 1;
  }
  header a {
    font-size: 0.889em;
    line-height: 1;
    text-transform: lowercase;
  }

  @media (max-width: ${breakpoints.lg}px) {

    .columns {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      width: 100vw;
      margin-left: 50%;
      padding-bottom: 10px;
      transform: translateX(-50%);

      &:before,
      &:after {
        content: '';
        display: block;
        width: var(--container-padding);
        flex: 0 0 auto;
      }
      .card {
        flex: 0 0 auto;
        width: 52vw;
        min-width: 160px;
        margin: 0 1rem 0 0;
        margin-right: var(--column-gap);

        @media (min-width: ${breakpoints.sm}px) {
          width: 33.33vw;
        }
        @media (min-width: ${breakpoints.lg}px) {
          width: 25vw;
        }
      }
      .card:last-child {
        margin-right: 0;
      }
      &::-webkit-scrollbar {
        display: none;
      }
      &::-webkit-scrollbar-track,
      &::-webkit-scrollbar-thumb,
      &::-webkit-scrollbar-thumb:hover  {
        background-color: transparent;
      }
    }
  }
`;

const Categories = ({coupons, categories, ...props}) => {

  const sections = categories.reduce((result, category) => {
    const slug = category.toLowerCase();
    result[slug] || (result[slug] = {category});
    result[slug].coupons = coupons.filter(c => c.category === category);
    return result;
  }, {});

  return (
    <React.Fragment>

      {Object.entries(sections).map(([slug, {category, coupons}]) => (
        <section key={slug} className={cx(styles, 'container')}>

          <header>
            <h2>{category} ({coupons.length})</h2>
            <Link to={`/${slug}`}>view offers</Link>
          </header>

          <div className="columns">
            {coupons.map(coupon =>
              <Card
                key={coupon.id}
                tag={category}
                coupon={coupon}
                className="xs:col-6 sm:col-4 lg:col-3"
                onClick={e => props.history.push(`/offers/${coupon.id}`)}
              />
            )}
          </div>

        </section>
      ))}

    </React.Fragment>
  )
};

export default withRouter(connect(state => ({
  categories: state.categories,
  coupons: state.coupons
}))(Categories));
