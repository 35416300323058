import { useState, useEffect, useCallback, useRef } from 'react';
import { noop } from '../utils';

export function useForm(
  initialState = {},
  validate = noop,
  submit = noop
) {

  const [ isSubmitting, setSubmitting ] = useState(false);
  const [ values, setValues ] = useState(initialState);
  const [ errors, setErrors ] = useState({});

  useEffect(() => {
    if (isSubmitting && Object.keys(errors).length === 0) submit();
  }, [errors]); // eslint-disable-line

  function onChange(event) {
    event.persist();
    const { name, value } = event.target;
    setValues(values => ({...values, [name]: value}));
  }

  function onSubmit(event) {
    event && event.preventDefault();
    const errors = validate(values);
    setSubmitting(true);
    setErrors(errors);
  }

  return { values, errors, setValues, setErrors, onChange, onSubmit };
};

export function useMedia(query) {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) setMatches(media.matches);
    const listener = () => setMatches(media.matches);
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
};

export function useUpdateEffect(effect, deps) {
  const isInitial = useRef(true);
  useEffect(
    isInitial.current ? () => { isInitial.current = false } : effect,
    deps
  );
};

export function useToggle(initial = false) {
  const [ on, setState ] = useState(initial);
  const toggle = useCallback(() => setState(on => !on), []);
  return [ on, toggle ];
};