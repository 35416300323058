import React from 'react';
import * as Sentry from '@sentry/browser';
import { css } from 'emotion';
import { colors } from '../styles';

const styles = css`
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.orange};

  a, a:hover {
    color: #FFF;
  }
`;

export default class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {error: null, eventId: null};
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.error) {
      return (
        <div className={styles}>
          <a href="#" onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report Feedback</a> {/* eslint-disable-line */}
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}