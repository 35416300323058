import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from '../store';
import { isEmpty } from '../utils';
import Landing from '../pages/Landing';

export default function withAuth(WrappedComponent) {

  class Auth extends React.Component {

    componentDidMount() {
      this.check();
    }

    componentDidUpdate() {
      this.check();
    }

    check() {
      const { isAuthed, history } = this.props;
      isAuthed || history.push('/');
    }

    render() {
      if (this.props.isLoading) return null;
      return this.props.isPassholder ? <WrappedComponent {...this.props} /> : <Landing />
      // return this.props.isLoading ? null : <WrappedComponent {...this.props} />
    }
  }

  return withRouter(connect(state => ({
    isLoading: state.isLoading,
    isAuthed: state.isAuthed,
    isPassholder: !isEmpty(state.coupons)
  }))(Auth));
};