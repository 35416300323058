import React from 'react';
import axios from 'axios';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { polyfills } from './device';
import { Provider } from './store';
import ErrorBoundary from './components/ErrorBoundary';
import App from './components/App';
import pkg from '../package.json';
import * as Sentry from '@sentry/browser';
import * as worker from './sw';

Sentry.init({
  dsn: 'https://665b2ab8c6a445728aec834bfc47c635@sentry.io/1406936',
  environment: process.env.REACT_APP_ENV || process.env.NODE_ENV,
  release: `${pkg.name}@${pkg.version}`,
  beforeSend: event => process.env.REACT_APP_ENV === 'local' ? null : event
});

axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';

axios.interceptors.response.use(null, error => {

  const expected = (
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500
  );

  if (!expected) {
    Sentry.withScope(scope => {
      const user = JSON.parse(localStorage.getItem('user') || '{}');
      scope.setUser(user);
      scope.setExtra('error', error);
      scope.setTag('api', (error.request && error.request.responseURL) || '');
      Sentry.captureException(error);
    });
  }

  return Promise.reject({message: expected ? error.response.data.message : 'An error occured'});
});

function init() {

  const params = new URLSearchParams(window.location.search);

  if (params.has('_promo')) {
    window.sessionStorage.setItem('_promo', params.get('_promo'));
    window.location.replace(window.location.pathname);
  }

  const noscript = document.querySelector('noscript');
  noscript && (noscript.parentElement.removeChild(noscript));

  render(
    <ErrorBoundary>
      <Router>
        <Provider>
          <LastLocationProvider>
            <App />
          </LastLocationProvider>
        </Provider>
      </Router>
    </ErrorBoundary>,
    document.getElementById('root')
  );
}

if (polyfills.length) {
  const script = document.createElement('script');
  script.src = `//polyfill.io/v3/polyfill.min.js?features=${polyfills.join(',')}&flags=always&ua=${window.navigator.userAgent}`;
  script.onload = init;
  document.body.appendChild(script);
} else { init() }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// process.env.NODE_ENV === 'production' ?  worker.register() : worker.unregister();
worker.unregister();
