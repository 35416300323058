import React, { useEffect, useLayoutEffect } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { connect, actions } from '../store';
import { useToggle } from '../utils/hooks';
import withAuth from '../utils/auth';
import Header from './Header';
import Footer from './Footer';
import Preloader from './Preloader';
import Home from '../pages/Home';
import Categories from '../pages/Categories';
import Category from '../pages/Category';
import Favorites from '../pages/Favorites';
import Coupon from '../pages/Coupon';
import Account from '../pages/Account';
import Logout from '../pages/Logout';
import NotFound from '../pages/NotFound';
import '../styles/index.scss';

const App = ({history, isLoading, isAuthed}) => {

  const path = history.location.pathname;
  document.body.classList.remove('is-home', 'is-app');
  document.body.classList.add(isDeepLink(path) ? 'is-home' : 'is-app');

  const [ isLoginActive, toggleLogin ] = useToggle(path.startsWith('/login'));
  const [ isOrderActive, toggleOrder ] = useToggle(path.startsWith('/buy'));
  const lastLocation = useLastLocation();

  function isDeepLink(path) {
    return path === '/' || path.startsWith('/buy') || path.startsWith('/login') || (!isAuthed && path.startsWith('/offers'));
  }

  function onToggleLogin() {
    isLoginActive && history.replace('/');
    toggleLogin();
  }

  useLayoutEffect(() => {
    if (path.startsWith('/buy') && !isOrderActive) toggleOrder();
  }, [path, isOrderActive]); // eslint-disable-line

  useEffect(() => {
    actions.verify();
    window.addEventListener('online', actions.setStatus);
    window.addEventListener('offline', actions.setStatus);
    return ()  => {
      window.removeEventListener('online', actions.setStatus);
      window.removeEventListener('offline', actions.setStatus);
    };
  }, []);

  useLayoutEffect(() => {
    if (lastLocation && !lastLocation.pathname.startsWith('/offers/')) window.scrollTo(0, 0);
  }, [path, lastLocation]);

  return (
    <React.Fragment>
      {isLoading ? (
        <Preloader in={isLoading} unmountOnExit={true} />
      ) : (
        <React.Fragment>
          <Header
            toggleLogin={onToggleLogin}
            toggleOrder={toggleOrder}
          />
          <main className="main">
            <Switch>
              <Route
                exact
                path={[
                  '/',
                  '/buy',
                  '/login/:token?',
                  !isAuthed && '/offers'
                ].filter(Boolean)}
                render={(props) => (
                  <Home
                    {...props}
                    onLogin={toggleLogin}
                    isLoginActive={isLoginActive}
                    isOrderActive={isOrderActive}
                    toggleLogin={onToggleLogin}
                    toggleOrder={toggleOrder} />
                  )}
                />
              <Route exact path="/offers" component={withAuth(Categories)} />
              <Route path="/:category(eats|drinks|shops|experiences|lodging)" component={withAuth(Category)} />
              <Route path="/favorites" component={withAuth(Favorites)} />
              <Route path="/offers/:id" component={withAuth(Coupon)} />
              <Route path="/account" component={withAuth(Account)} />
              <Route path="/logout" component={Logout} />
              <Route path="*" component={NotFound} />
            </Switch>
          </main>
          <Footer />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default withRouter(connect(state => ({
  isLoading: state.isLoading,
  isAuthed: state.isAuthed
}))(App));
