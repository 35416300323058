import React from 'react';
import { css, cx } from 'emotion';
import { NavLink } from 'react-router-dom';
// import { Timeline, Expo } from '../utils/tween';
import { colors, fonts } from '../styles';
import withTransition from '../utils/transition';
import Social from './Social';
import Icon from './Icon';

const styles = css`
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 768px;
  padding: 2.5em;
  color: #FFF;
  font-size: 12px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  will-change: transform;
  transform: translateX(-100%);
  /* temporary styles until the svg skey is brought back */
  transition: transform 0.4s cubic-bezier(0.75,0.05,0.15,1);
  background-color: ${colors.orange};
  background-image: linear-gradient(0, ${colors.orangeLight} 0%, ${colors.orangeDark} 100%);

  &.in {
    transform: translateX(0);
  }
  .nav-gradient {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  .wrap {
    position: relative;
    z-index: 1;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    will-change: opacity;
  }
  .close {
    padding: 15px;
    margin-top: -15px;
    margin-left: -15px;
    color: #FFF;
    line-height: 0;
    transition: opacity 0.15s;

    &:hover {
      opacity: 0.5;
    }
    svg {
      width: 25px;
      height: 25px;
    }
  }
  .primary {
    width: 100%;
    margin: auto 0;
    padding: 2em 0;

    a {
      display: inline-block;
      margin: 0.125em 0;
      font-size: 2.25em;
      font-weight: 700;
      font-family: ${fonts.heading};
      line-height: 1;
      color: currentColor;
      transition: all 0.15s;

      &:hover {
        color: ${colors.red};
      }
      &.active {
        color: ${colors.red};
      }
    }
  }
  .secondary {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ul a {
      display: inline-block;
      font-size: 1rem;
      font-family: ${fonts.body};
      font-weight: 500;
      line-height: 1;
      color: currentColor;
      opacity: 0.7;
      transition: opacity 0.15s;

      &:hover {
        color: #FFF;
        opacity: 1;
      }
    }
  }
  .social a {
    margin-left: 15px;
    color: ${colors.red};
  }

  @media (min-width: 480px) {
    font-size: 15px;
    padding: 3em;
  }

  @media (min-width: 768px) {
    font-size: 18px;
    padding: 4em;
  }

  @media (min-width: 1200px) {
    font-size: 20px;
    .primary a {
      margin: 0.075em 0;
    }
  }

  /* HACK: fix collapsing issue on mobile landscape mode */
  @media (max-height: 480px) { .secondary { margin-bottom: 3em; } }
`;

class Nav extends React.Component {

  constructor(props) {
    super(props);
    this.onDocumentClick = this.onDocumentClick.bind(this);
  }

  componentDidMount() {
    // this.timeline = new Timeline({paused: true})
    //   .to(this.path, 0.35, {attr: {d: 'M 0,0 4,0 3,10 0,10 Z'}, ease: Expo.easeIn})
    //   .to(this.path, 0.85,  {attr: {d: 'M 0,0 10,0 10,10 0,10 Z'}, ease: Expo.easeOut})
    //   .from(this.wrap, 0.35, {opacity: 0}, "-=0.6");
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onDocumentClick);
    document.documentElement.classList.remove('no-overflow');
    document.body.classList.remove('no-overflow');
  }

  animateIn(node, done) {
    this.el.scrollTop = 0;
    // this.timeline.restart().play();
    document.addEventListener('click', this.onDocumentClick);
    document.documentElement.classList.add('no-overflow');
    document.body.classList.add('no-overflow');
    done();
  }

  animateOut(node, done) {
    // TODO: add timeline labels to rever the easing order when animating out
    // this.timeline.kill().reverse();
    document.removeEventListener('click', this.onDocumentClick);
    document.documentElement.classList.remove('no-overflow');
    document.body.classList.remove('no-overflow');
    done();
  }

  onDocumentClick(event) {
    // TODO: add overlay to prevent click through and avoid the need for this!
    if (!this.el.contains(event.target) && this.props.in) {
      this.props.close();
    }
  }

  render() {
    return (
      <nav ref={el => this.el = el} className={cx(styles, this.props.in && 'in')}>

        <div ref={el => this.wrap = el} className="wrap">
          <button className="close" onClick={this.props.close}>
            <Icon name="arrow-left" />
          </button>
          <div className="primary">
            <ul>
              <li><NavLink onClick={this.props.close} to="/offers">All Offers</NavLink></li>
              <li><NavLink onClick={this.props.close} to="/eats">Eats</NavLink></li>
              <li><NavLink onClick={this.props.close} to="/drinks">Drinks</NavLink></li>
              <li><NavLink onClick={this.props.close} to="/shops">Shops</NavLink></li>
              <li><NavLink onClick={this.props.close} to="/experiences">Experiences</NavLink></li>
              <li><NavLink onClick={this.props.close} to="/lodging">Lodging</NavLink></li>
              <li><NavLink onClick={this.props.close} to="/favorites">Favorites</NavLink></li>
            </ul>
          </div>
          <div className="secondary">
            <ul>
              {/* <li><NavLink onClick={this.props.close} to="/account">Account</NavLink></li> */}
              {/* TODO: let the compenent animate out before calling close?! */}
              <li><NavLink to="/logout">Logout</NavLink></li>
            </ul>
            <Social style={{color: colors.red}} />
          </div>
        </div>

        <svg className="nav-gradient" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" preserveAspectRatio="none">
          <defs>
            <linearGradient id="nav-gradient" x1="0" y1="0" x2="0" y2="100%">
              <stop stopColor={colors.orangeDark} offset="0" />
              <stop stopColor={colors.orangeLight} offset="100%" />
            </linearGradient>
          </defs>
          <path ref={el => this.path = el} fill="url('#nav-gradient')" d="M 0,0 0,0 0,10 0,10 Z" />
        </svg>

      </nav>
    );
  }
}

export default withTransition(Nav);