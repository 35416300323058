import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { css, cx } from 'emotion';
import { connect } from '../store';
import Card from '../components/Card';

// TODO: refactor to not duplicate this code with Categories.js

const styles = css`
  margin-top: 2rem;
  margin-bottom: 2rem;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  header h2 {
    font-size: 1.25rem;
    line-height: 1;
  }
  header a {
    font-size: 0.889em;
    line-height: 1;
    text-transform: lowercase;
  }
`;

const Category = ({category, coupons, ...props}) => (
  <section className={cx(styles, 'container')}>
    <header>
      <h2>{category} ({coupons.length})</h2>
      <Link to="/offers">all offers</Link>
    </header>
    <div className="columns">
      {coupons.map(coupon =>
        <Card
          key={coupon.id}
          tag={category}
          coupon={coupon}
          className="xs:col-6 sm:col-4 lg:col-3"
          onClick={e => props.history.push(`/offers/${coupon.id}`)}
        />
      )}
    </div>
  </section>
);

export default withRouter(connect((state, props) => {
  const slug = props.match.params.category.toLowerCase();
  const category = slug.charAt(0).toUpperCase() + slug.slice(1);
  const coupons = state.coupons.filter(c => c.category === category);
  return {category, coupons};
})(Category));
