import React from 'react';
import { withRouter } from 'react-router-dom';
import { css, cx } from 'emotion';
import { connect } from '../store';
import Card from '../components/Card';

const styles = css`
  margin-top: 2rem;
  margin-bottom: 2rem;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  header h2 {
    font-size: 1.25rem;
    line-height: 1;
  }
  header a {
    font-size: 0.889em;
    line-height: 1;
    text-transform: lowercase;
  }
`;

const Favorites = ({coupons, ...props}) => {

  return (
    <section className={cx(styles, 'container')}>
      {coupons.length ? (
        <React.Fragment>
          <header>
            <h2>Favorites ({coupons.length})</h2>
          </header>
          <div className="columns">
            {coupons.map(coupon =>
              <Card
                key={coupon.id}
                coupon={coupon}
                className="xs:col-6 sm:col-4 lg:col-3"
                onClick={e => props.history.push(`/offers/${coupon.id}`)}
              />
            )}
          </div>
        </React.Fragment>
      ) : (
        <div className="text-center my-4 px-1">
          <h1 className="mb-1">Save Your Favorites</h1>
          <p>Tap the heart in the right corner of a photo to favorite an offer and it'll show up here.</p>
        </div>
      )}
    </section>
  );
};

export default withRouter(connect(state => ({
  coupons: state.coupons.filter(c => c.favorite)
}))(Favorites));