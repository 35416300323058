import React, { useRef } from 'react';
import { css } from 'emotion';
import { connect } from '../store';
import { useMedia } from '../utils/hooks';
import { isEmpty } from '../utils';
import { colors } from '../styles';
import Button from './Button';

const styles = css`
  height: 92vh;
  max-height: 1100px;
  min-height: 650px;
  background-color: ${colors.blueDark};
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 50%;
    background: url(${require('../images/hero-texture.png')}) 50% 100%;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
    pointer-events: none;
  }
  .container {
    display: flex;
    align-items: center;
    max-width: 1600px;
    height: 100%;
    padding: 0 6vw;
    position: relative;
    z-index: 2;
    transform: translate3d(0,0,0);

    > div {
      margin-top: 4rem;
    }
  }
  h1 {
    font-size: 2.25rem;
    line-height: 1.125;
    max-width: 630px;
    margin-bottom: 0.5em;
    color: #FFF;
    text-shadow: 0 0 50px rgba(${colors.rgb.blueDark},0.25);
  }
  p {
    width: 95%;
    max-width: 485px;
    color: #FFF;
    text-shadow: 0 0 15px rgba(${colors.rgb.blueDark},0.5);
  }
  .media {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;

    img, video {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      width: auto;
      height: auto;
      min-width: 100%;
      min-height: 100%;
      max-width: none;
    }
  }

  @media (min-width: 768px) {

    h1 {
      font-size: 2.85rem;
      max-width: 700px;
    }
  }

  @media (min-width: 1100px) {

    h1 {
      font-size: 3rem;
    }
  }
`;

const HomeHero = ({toggleOrder, isAuthed, isPassholder}) => {

  const node = useRef();
  const video = useRef();
  const isDesktop = useMedia('(min-width: 1024px)');

  return (
    <section ref={node} className={styles}>

      <div className="container">
        <div>
          <h1>Free Drinks, <br className="hide md:show" />2 for 1 Offers &amp; More</h1>
          <p>Discovery Pass is a smartphone-based savings pass with over $800 in deals on eats, drinks, shops, lodging and experiences in Salida and Buena Vista, Colorado.</p>
          {(isAuthed && isPassholder)? (
            <Button href="/offers">Access All Offers</Button>
          ) : (
            <Button onClick={toggleOrder}>Get the Annual Pass</Button>
          )}
        </div>
      </div>

      <div className="media">
        <video ref={video} src={`https://d1bbidulofuuly.cloudfront.net/media/hero-w20-${isDesktop ? '1600x900' : '1024x576'}.mp4`} loop muted autoPlay playsInline></video>
      </div>

    </section>
  );
};

export default connect(state => ({
  isAuthed: state.isAuthed,
  isPassholder: !isEmpty(state.coupons)
}))(HomeHero);
