import React from 'react';

import { ReactComponent as ArrowLeft } from '../images/icon-arrow-left.svg';
import { ReactComponent as Close } from '../images/icon-close.svg';
import { ReactComponent as Heart } from '../images/icon-heart.svg';
import { ReactComponent as Menu } from '../images/icon-menu.svg';
import { ReactComponent as Facebook } from '../images/icon-facebook.svg';
import { ReactComponent as Instagram } from '../images/icon-instagram.svg';
import { ReactComponent as Twitter } from '../images/icon-twitter.svg';

export const Icons = {
  ArrowLeft: 'arrow-left',
  Close: 'close',
  Hamburger: 'hamburger',
  Heart: 'heart',
  Facebook: 'facebook',
  Instagram: 'instagram',
  Twitter: 'twitter',
};

export default props => {
  switch(props.name) {
    case 'arrow-left':
      return <ArrowLeft {...props} />
    case 'close':
      return <Close {...props} />
    case 'heart':
      return <Heart {...props} />
    case 'menu':
      return <Menu {...props} />
    case 'facebook':
      return <Facebook {...props} />
    case 'instagram':
      return <Instagram {...props} />
    case 'twitter':
      return <Twitter {...props} />
    default:
      return null;
  }
};