import React from 'react';
import { css } from 'emotion';
import Button from './Button';

const styles = css`
  padding-top: 4rem;
  padding-bottom: 2rem;
  text-align: center;

  h1 {
    max-width: 720px;
    margin: 0 auto 1em auto;
    font-size: 1.75em;
    line-height: 1.4;
  }

  @media (min-width: 1024px) {
    padding-top: 7rem;
    padding-bottom: 3.5rem;
    text-align: left;

    .container {
      max-width: 1400px;
      padding: 0 5vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    h1 {
      max-width: 67%;
      margin: 0;
    }
  }
`;

export default props => (
  <article className={styles}>
    <div className="container">
      <h1>Support local and save on your next adventure to Salida and Buena Vista, Colorado</h1>
      <Button onClick={props.toggleOrder}>Get Your Discovery Pass</Button>
    </div>
  </article>
);