import React from 'react';
import { css } from 'emotion';
import { colors } from '../styles';

const styles = css`
  padding-top: 3rem;
  padding-bottom: 2rem;
  background-color: #FFF;

  h1 {
    margin-bottom: 0.5em;
  }
  strong {
    display: block;
    color: ${colors.blue};
  }
  .wrap {
    max-width: 520px;
    margin: 0 auto 2rem auto;
  }
  .device {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    transform: translateX(-11.25%);

    &:after {
      content: '';
      position: relative;
      z-index: 1;
      display: block;
      width: 100%;
      padding-top: 161.5%;
      background: url(${require('../images/device.png')}) center no-repeat;
      background-size: contain;
    }
    video {
      position: absolute;
      z-index: 0;
      top: 7.75%;
      left: 31%;
      width: 60%;
      height: auto;
    }
  }
  li {
    margin-top: 1.5em;
  }

  @media (min-width: 1024px) {

    .container {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-start;
      align-items: center;
    }
    .wrap {
      max-width: 440px;
    }
  }
`;

export default props => (
  <section className={styles}>
    <div className="container">
      <div className="wrap">
        <h1>How <strong>Discovery Pass</strong> Works</h1>
        <ol>
          <li>
            <h4>Purchase Pass</h4>
            {/* eslint-disable-next-line */}
            <p>Buy your Discovery Pass through this site. Be sure to check our <a href="#" onClick={e => (e.preventDefault(), props.toggleQuestions())}>Frequently Asked Questions</a> for information on compatible mobile devices.</p>
          </li>
          <li>
            <h4>Open Link with Mobile Device</h4>
            <p>Upon purchase, we'll send you an email with a link to your Discovery Pass. Open this link from your mobile device.</p>
          </li>
          <li>
            <h4>Start Discovering</h4>
            <p>Log in, browse offers, save favorites and start redeeming. Save the app to the home screen of your device for the best experience.</p>
          </li>
        </ol>
      </div>
      <div className="device">
        <video src={require('../images/steps.mp4')} loop muted autoPlay playsInline></video>
      </div>
    </div>
  </section>
);