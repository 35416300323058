const data = [
// Remove "Boutique" from Cause+Medic Spa & Wellness (NOTE: 2 offers)
// Change Greater Arkansas River Nature Association to GARNA
{teaser: true, disclaimer: false, vendor: {name: "Tres Litros Beer Company", website: "https://www.treslitrosbeer.co"}, offer: "Buy one beer, get one free", photo: "imig809IxLEwUFalrbMyLRQGNeFWglHDSEIRDyEM.jpg"},
{teaser: true, disclaimer: false, vendor: {name: "Robin's in Salida", website: "https://www.facebook.com/RobinsSalida/"}, offer: "2 for 1 entrées", photo: "NXFcgpQnG5cM2qTbLgZbAdJWzXVpKbLyXhMaOy5z.jpg"},
{teaser: true, disclaimer: false, vendor: {name: "Chill Salida Ice Cream", website: "https://www.chillsalida.com/"}, offer: "Buy one ice cream treat, get one free", photo: "eh3LYZgq7c9Qj7lKMNk9pFdeGtSopyYaBg04XRLz.jpg"},
{teaser: true, disclaimer: false, vendor: {name: "Soulcraft Brewing", website: "https://soulcraftbeer.com/"}, offer: "Buy one get one free pints", photo: "n6nqib7q694miDFQQivT8kBlpVDRRi7Fi918GVR9.jpg"},
{teaser: true, disclaimer: false, vendor: {name: "Monarch Dog Sled Rides", website: "https://monarchdogsledrides.com/"}, offer: "10% off weekday trips", photo: "WTAYgH7WZvPbt4BjSIpYvzkeCqQdwcWR2r8qJq2l.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "High Rockies Cuisine", website: "https://highrockiescuisine.com/"}, offer: "Buy one menu item and get one free", photo: "oBdbFxfTvkFEKK97IMcO7oxEzNxHdF4SvR9mFnE9.jpg"},
{teaser: true, disclaimer: false, vendor: {name: "push & pull", website: "https://pushandpullbv.com"}, offer: "Buy a beer and get one free", photo: "2P11hQzgMV6qq4aO8Xzm5IyAMleACeGjg84jTATR.jpg"},
{teaser: true, disclaimer: false, vendor: {name: "Surf Hotel & Chateau", website: "https://surfhotel.com/"}, offer: "10% off your stay", photo: "e7zw6eQZGWx0dqBnXMKY2alvbaW7jEafxfNb9k5Q.jpg"},
{teaser: true, disclaimer: false, vendor: {name: "Deerhammer Distilling Company", website: "https://www.deerhammer.com"}, offer: "2 for 1 barrel-aged cocktails", photo: "ZeUrHXesxzgLBNNykdyUTQFG5eCcbOOtKJX7pAJN.jpg"},
{teaser: true, disclaimer: false, vendor: {name: "Moonlight Pizza & Brewpub", website: "https://moonlightpizza.biz"}, offer: "Buy one get one free beer or soda", photo: "eY3E68VzjsKGsP1CDadwfs2wDuCBQR6Dq0X96prO.jpg"},
{teaser: true, disclaimer: false, vendor: {name: "push & pull", website: "https://pushandpullbv.com"}, offer: "Buy a waffle and get one free", photo: "OiKJRQUay72QGiRJS39pJjYG4aokRVZK5Md24UF1.jpg"},
{teaser: true, disclaimer: false, vendor: {name: "Wood's High Mountain Distillery", website: "https://woodsdistillery.com/"}, offer: "Free gin flight with cocktail purchase", photo: "VU3ozBPvn1oklaN7c7ePG3NS5DlZtaUEqR3rjX0p.jpg"},
{teaser: true, disclaimer: false, vendor: {name: "Vino Salida Wine Cellars", website: "https://www.vinosalida.com/"}, offer: "Free beer, cocktail, or glass of wine", photo: "tMUBJa4bahpAygTOYrDigEAnDvd2NVAQ7EormKHP.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "River Runners", website: "https://whitewater.net/"}, offer: "10% off rafting*", photo: "PM6vQeV8nuIq03UQ1KCCIH4SuPpftrrr3h1i3xgh.jpg"},
{teaser: true, disclaimer: false, vendor: {name: "The Michael Clark Gallery", website: "https://www.michaelclarkfineart.com"}, offer: "20% off original artwork $1500 and under", photo: "HTZxS4dfjtHTkOL7dC49bs2VelmA90e8ygHGloQ9.png"},
{teaser: false, disclaimer: false, vendor: {name: "Independent Whitewater", website: "https://www.independentrafting.com/"}, offer: "Free pint with meal purchase at The Drift*", photo: "hl10Frr8ozIRjyyKKd7n0ej7akAO3FPp2puT4jcw.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Independent Whitewater", website: "https://www.independentrafting.com/"}, offer: "$15 off your Browns Canyon half day rafting trip*", photo: "kKwuLFj6sZh2aYS3Cci81NLPxhRBI7jFluKZ6euM.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Rocky Mountain Jeep Rentals", website: "https://www.rockymountainjeeprentals.com/"}, offer: "$25 off a Jeep Wrangler rental*", photo: "fzfaDQjwmg1a7PcQAB43gWfmJDD2hD9wbGbhmhQG.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Salida River Adventures", website: "https://www.salidariveradventures.com/"}, offer: "$15 off a Browns Canyon half day rafting trip*", photo: "JAWav4XTNZD0A1O7NYoZgI7BSExTO9DBCpwSU7bB.jpg"},
{teaser: true, disclaimer: false, vendor: {name: "Salida Pharmacy & Fountain", website: "https://www.salidapf.com"}, offer: "Buy one soda fountain treat, get one free", photo: "C0VTEttF16u6tc6PeOp9P6DVOSMJyJvwNxSxUpRL.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Amicas Pizza", website: "https://amicassalida.com/"}, offer: "One free beer with purchase of entree", photo: "frqtoihaGBvCOY7NUyfBiZMQq8jD19lUm4hnRNpe.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Adventure Outpost by Howl", website: "https://howlmercantile.com/blogs/news/our-new-expanded-location-adventure-outpost-by-howl"}, offer: "15% off full price purchase", photo: "ADclZUkMWY062IyG5CWgSqFcFEVYBp1ZPDnfewPw.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "High Side! Bar & Grill", website: "https://highsidesalida.com/"}, offer: "Buy one pint, get one free", photo: "JdLpusXbAwoHFSBc2gs7jKVJonn3GvXcmy0vqtOq.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Wood's High Mountain Distillery", website: "https://woodsdistillery.com/"}, offer: "10% discount on a bottle or 4-pack canned cocktail purchase", photo: "QZimckpCmyatIFHuA1WSkx5CM4vejXw7paRhUZgl.png"},
{teaser: true, disclaimer: false, vendor: {name: "Wesley & Rose", website: "https://surfhotel.com/wesley-rose/"}, offer: "Buy one get one free craft cocktails", photo: "DFcMKeqs2S2L6S7Snbrm0VULbOg7wQbAtW1gWYie.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "The Buena Viking", website: "https://www.buenaviking.com/"}, offer: "Free small tot with the purchase of a sandwich*", photo: "N4L03mC0P7q1qAYvkqzu3dIp541THk8wwtGYsEqc.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Mojo's Eatery", website: "https://mojoseatery.com"}, offer: "Buy one beer, get one half off", photo: "7JsAaTzDmz6hhb8gajeqJ4M1cZgA3uzdCDPvEFnF.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Bunny and Clyde's Corner Café", website: "https://www.facebook.com/bunnyandclydessalida/"}, offer: "Buy one get one half off breakfast or lunch", photo: "4kQTtos7a1r3j81OSFrpBhIWPreeVN7vOuWXeVaC.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Fay and Maye", website: "https://www.instagram.com/fayandmaye"}, offer: "$5 off a purchase of $30 or more", photo: "VvVoiVOFpj7r5hHyRlwHXYQHXz6yQbG12EhAYMy6.jpg"},
{teaser: true, disclaimer: false, vendor: {name: "The Cellar on Railroad", website: "https://thecellarbv.com/"}, offer: "Buy one glass of wine or beer, get one free", photo: "8SMkjjzDJVq5dhJG3dUdZyR6ltF5tQ537yWw7TXR.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "GARNA", website: "https://garna.org/"}, offer: "50% off one annual Family Membership", photo: "mW1Yts8ctINZibdBKWBVY4L711EegubZgwUSofi1.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Eddyline Brewery", website: "https://eddylinebrewing.com/"}, offer: "Free chips and salsa with purchase of $15 or more", photo: "qpVHq1u5bfebwspEPebiOhzBAXc1Ip9kuZQiHRvW.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Rock Paper Scissors", website: "https://www.instagram.com/rockpaperscissorsbv/"}, offer: "$5 off $25 or more", photo: "Io9WTPlh85q4iYzTuxiWrIGdqXjAfzKKvhGozRLh.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "the Blend", website: "https://www.theblendbv.com/"}, offer: "Free 12 ounce drip coffee", photo: "jtUvlRZAGbMg81NZv5XJxfs03PjpCLRuTmS1kOts.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Cause+Medic Spa & Wellness", website: "https://www.causemedicspa.com"}, offer: "Free Aromatherapy or Foot Exfoliation & Hydration enhancement for new clients", photo: "W0GEeJvLspg39VfDf1mho43VdN2l972uVpT2FnJp.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Cause+Medic Spa & Wellness", website: "https://www.causemedicspa.com"}, offer: "Free gift with retail purchase of $25 or more", photo: "JyYU1YnHcpY7tbKXWkEeF3nPp9rKzZOMzRdyWw4L.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Rock Run Gallery", website: "https://www.rockrungallery.com"}, offer: "15% off select NoreenArt", photo: "ms7pIPFYgmXVv2z8CTd6waFFJnMPbFva3DgadD9c.jpg"},
{teaser: true, disclaimer: false, vendor: {name: "Simple Eatery & Spoon It Up", website: "https://www.spoon-it-up.com/"}, offer: "Free loaf of bread with purchase of entrée", photo: "pO2QGbxO1S8Y2luFzGIPlVnD0AU0tXaMJ8KeLB7s.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Sorelle Delicatessen", website: "https://www.sorelledelicatessen.com/"}, offer: "Buy one get one half off sandwiches", photo: "f5YieF1A3X6Gu2jhvxbOTc1QVxo11mjrN2sYcEOE.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Little Red Hen Bakery", website: "https://www.facebook.com/littleredhensalida"}, offer: "Free 12 ounce hot coffee or hot tea with a purchase of $3 or more", photo: "JA3NovLjGo35N0S9Nn1vIV3N5jOP5JCE7rc4A5CU.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "American Classic Inn", website: "https://www.americanclassicinn.com"}, offer: "$10 off your nightly rate", photo: "kXXxTOvztvvLsyHLOvZWukLyzdHdYLTCbHHVH5Ta.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Integrity Tattoo", website: "https://integritytattoo.rocks/"}, offer: "$30 off your first hour of tattooing", photo: "3KHIQKBuXUvHJYDgkJnk3XDn3scQQ31M7AV7Hhs0.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Robin's in Salida", website: "https://www.facebook.com/RobinsSalida/"}, offer: "2 for 1 beer or canned cocktail", photo: "XJvAnVMRTlYR5DgdYuUxXQnK0c0Z8o34st4NkoLM.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Brown Dog Coffee", website: "https://browndogcoffee.com"}, offer: "Buy one breakfast burrito and get the second half off", photo: "HUjdnWLFBFSlXRSxhhWHxxTTnYCyAXUsyQZQDCub.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "50 Burger Shakes & Beer", website: "http://50burgersalida.com/"}, offer: "Buy one beer, wine or well cocktail and get one free", photo: "7aLUC1ShqXbQg9sPHV03IpLxjzfNk0aOB3si3HNC.png"},
{teaser: false, disclaimer: false, vendor: {name: "the YogaTonic", website: "https://www.theyogatonic.com/"}, offer: "$10 off your purchase of $50 or more", photo: "a3MkVZNdm0TEFZtIj1jjQYnaVEIiiMzvoGp4bi2C.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Robin's in Salida", website: "https://www.facebook.com/RobinsSalida/"}, offer: "2 for 1 mimosas", photo: "AFEfX0dnJN8psUSWPl7geECEIClCMw5PdvYySQeH.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Scanga Meat Company", website: "https://www.facebook.com/SCANGAMEATCO"}, offer: "$10 off your purchase of $50 or more", photo: "3UwBWGeRmaPLFJZ8zN3AYfiMKz8EfUbldRXF9D4B.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Howl Mercantile", website: "http://www.howlmercantile.com/"}, offer: "2 for 1 single scoop of ice cream", photo: "Wm00bEl3HaYo5wTpktbDZcvTz36f31KWdbd6Tl6u.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Creekside Chalets and Cabins", website: "https://creeksidechalets.com/"}, offer: "$25 pet fee waived", photo: "cBNqX6xbkLsmfDh3nBQyCIUPfgHIByEqgwdE8PEo.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Las Camelinas", website: "https://www.facebook.com/salidacamelinas/"}, offer: "50% off nachos with purchase of an entree", photo: "keuFs0hghe8kUTmaRxigrO7PBsMBq3NNK3rQHQI5.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "The Book Haven", website: "https://www.salidabooks.com/"}, offer: "$5 off when you spend $50 or more", photo: "4GlQZu3d29xkhybHGkaKkJpotFzsKRDUg83kLSi9.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Souled Out T-Shirts", website: "https://www.souledouttshirts.com/"}, offer: "Buy one get one half off t-shirts, hoodies or hats", photo: "wjygrqX3cR99gFPh9bXBoh2UxNPtRjzosnAXvYY0.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Hanuman Healing", website: "https://www.hanumanholistichealing.com"}, offer: "$10 off massages of 60 minutes or more", photo: "ZWc3O0im6pv64zJ5UlzoOwML4i1ynEMvhioXcMyF.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "BV eBike Rentals", website: "https://www.bvebikerentals.com"}, offer: "Rent one e-bike and get one free*", photo: "lmcJQ9XWqKO8h3SefeyVB0rZcgMV6qVSaNhNviwT.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "The Buena Viking", website: "https://www.buenaviking.com/"}, offer: "2 for 1 Elevation beer*", photo: "9PKnDPz9d3nSwduNgfulTj65LtkVd2Fe7vATcifF.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Colorado Sports Recycler", website: "https://www.coloradosportsrecycler.com/"}, offer: "$10 off a bike tuneup", photo: "7ph0O72oq67m9p71JCJXUSIvMEbr6QBFX6LCyH7k.png"},
{teaser: false, disclaimer: false, vendor: {name: "Riveting Experience Jewelry", website: "https://rivetingexperiencejewelry.com/"}, offer: "$10 off any project of $30 or more", photo: "XnX6rfUwGknrOyBABQqpjkh5FWUMU7OsRrsdMhvd.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Cafe Dawn", website: "http://www.cafe-dawn.com/"}, offer: "$5 off travel mugs", photo: "gmBhrEdTg65612DBPirfJ99Dw6sVrZqWnyZdQ4HY.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Vital Living Herbs & Nutrition", website: "http://vitallivingherbs.com/"}, offer: "25% off one elementé item", photo: "f7LzEjWXUm6YFecaIulw3jWb0MPe7K9uJ9YgoFyq.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "The Mixing Bowl", website: "https://salidamixingbowl.com/"}, offer: "10% off your purchase", photo: "rdmsGdWK7TGDGY55SQKDCPWXfgT9fKDDZ1ONBltT.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "7000 Feet Running Company", website: "https://7kft.co/"}, offer: "Save $20 on a purchase of $100 or more", photo: "lqYVdjWA1mwigF8ym7P3F0EUfYglkbvQXh5tKUot.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Opal Boutique", website: "https://www.facebook.com/opalboutiquesalida"}, offer: "15% off 1 regular priced item valued at $50 or more", photo: "wdOcBvbpvoZU26Jphdd9XlHQklRnjfMLxK53BUMU.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Yolo Clothing", website: "https://www.yolocolorado.com/"}, offer: "Free leggings with purchase of $50 or more", photo: "y0ELiJZe0aeL5Fwh5PqZWArx48Ca6A5x4PRFx3GF.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Little Red Tricycle", website: "http://www.littleredtricyclesalida.com/"}, offer: "$10 off a purchase of $30 or more", photo: "dIDR4dB8ZQ1JqlaEssKtvm8D9yMlh1PE31XhWoN4.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "The Beekeeper's Honey Boutique", website: "https://beekeepershoneyboutique.com/"}, offer: "Free pack of honeystix with any purchase", photo: "CJ8JLvMc0axS8IsrF0519MDigukLgAkOMXSjpaEO.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Salida Mountain Sports", website: "https://salidamountainsports.com/"}, offer: "$10 off a purchase of $100 or more", photo: "EBsyFFh07nUyr5nn8YhXh2jUrN1qSCYk82qXvXKa.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Vino Salida Wine Cellars", website: "https://www.vinosalida.com/"}, offer: "Complimentary bruschetta with purchase of an in-house bottle of wine", photo: "rko9TqBwmDGh5A0I9p5UN46UwMgQXA1nxnKDTh4z.jpg"},
{teaser: false, disclaimer: false, vendor: {name: "Kaleidoscope Toys", website: "https://www.kaltoys.com/"}, offer: "$5 off a purchase of $30 or more", photo: "yiIB4SE2lfRCwYxDiepxQRePOtARDRW1UufweRvp.jpg"}
];

export default data;
const temp = [];

export const vendors = data.reduce((result, {vendor}) => {
  if (!temp.includes(vendor.name)) {
    temp.push(vendor.name);
    result.push({name: vendor.name, website: vendor.website});
  }
  return result;
}, []);

export const teasers = data.filter(item => item.teaser);
