import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from '../store';

const NotFound = props => {

  if (!props.isAuthed) {
    return <Redirect to="/" />
  }

  return (
    <div className="text-center my-5 px-3">
      <h1 className="mb-1">Not Found</h1>
      <p>Uh oh. We couldn't find the page you're looking for.</p>
    </div>
  );
};

export default connect(state => ({
  isAuthed: state.isAuthed
}))(NotFound);