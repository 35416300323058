import React from 'react';

export default props => (
  <div className="content">
    <h1>Frequently Asked Questions</h1>
    <p className="lead">Top things to know about Discovery Pass.</p>
    <h2>How does it work?</h2>
    <ol>
      <li>After you've purchased your Discovery Pass, we'll email you a link to launch the Pass on your mobile device.</li>
      <li>Save the Discovery Pass to your device home screen for quick access, it will function like an app.</li>
      <li>To redeem an offer, click the orange "Redeem" button when you're ready to transact, then show the confirmation screen to the business associate.</li>
      <li>Enjoy your savings and don't forget to tip on the original amount!</li>
    </ol>
    <h2>How long is the Pass good for?</h2>
    <p>Discovery Pass is valid November 1 through October 31 of the following year.</p>
    <h2>How do I redeem an offer?</h2>
    <p>Offers must be redeemed and validated in person. Let the business associate know that you have a Discovery Pass. Tap the orange redeem button to reveal a "Confirm Redemption" button. Show the following confirmation screen to the business associate.</p>
    <h2>Can an offer be redeemed more than once?</h2>
    <p>Some businesses allow you to redeem their offer multiple times, however, they can only redeemed once per day.</p>
    <h2>What type of mobile device do I need to have in order to use the Discovery Pass?</h2>
    <p>A smartphone that has access to the web via Wi-Fi or cellular data is required. Flip phones, rotary phones and satellite phones will not be supported. If saving Discovery Pass to your home screen is not an option for your particular device, you can always login to the website to access your offers.</p>
    <h2>Can I add my business to the Discovery Pass?</h2>
    <p>We love our Chaffee County local businesses! Email us at <a href="mailto:info@discoverypass.co">info@discoverypass.co</a> and let's chat.</p>
    {/* eslint-disable-next-line */}
    <a href="#" onClick={e => (e.preventDefault(), props.toggleQuestions())}>Return to site</a>
  </div>
);
