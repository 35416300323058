import React from 'react';
import { cx } from 'emotion';
import { actions } from '../store';
import { isEmpty } from '../utils';
import { useForm, useToggle } from '../utils/hooks';
import Button from '../components/Button';

function LoginResetForm(props) {

  const { values, errors, setValues, onChange, onSubmit } = useForm({}, validate, submit);
  const [ isLoading, toggleLoading ] = useToggle();
  const [ isError, toggleError ] = useToggle();
  const disabled = isEmpty(values.password) || isEmpty(values.passwordConfirm) || isLoading;

  function submit() {
    toggleLoading();
    actions.resetPassword({...values, token: props.token}).then(
      res => {
        props.close();
      },
      err => {
        setValues({});
        toggleLoading();
        toggleError();
      },
    );
  }

  function validate(values) {
    const { password, passwordConfirm } = values;
    const errors = {};

    if (isEmpty(password.trim())) {
      errors.password = 'Password is required';
    } else if (password.length < 4) {
      errors.password = 'Please enter a password with at least 4 characters';
    } else if (password !== passwordConfirm) {
      errors.passwordConfirm = 'Password does not match';
    }

    return errors;
  }

  return (
    <form onSubmit={onSubmit} autoComplete="off" noValidate={true}>

      {isError && (
        <React.Fragment>
          <p className="error mb-1">Unable to reset password.</p>
          <p className="small mb-2">This could be due to an invalid link or expired request. Please click the link in the email we sent to try again or submit a new password reset request.</p>
        </React.Fragment>
      )}

      <div className={cx('field', errors.password && 'has-error')}>
        <input
          type="password"
          name="password"
          value={values.password || ''}
          onChange={onChange}
          className={`input ${errors.password && 'has-error'}`}
          placeholder="Password"
          required
        />
        {errors.password && <span className="help">{errors.password}</span>}
      </div>

      <div className={cx('field', errors.passwordConfirm && 'has-error')}>
        <input
          type="password"
          name="passwordConfirm"
          value={values.passwordConfirm || ''}
          onChange={onChange}
          className={`input ${errors.passwordConfirm && 'has-error'}`}
          placeholder="Confirm Password"
          required
        />
        {errors.passwordConfirm && <span className="help">{errors.passwordConfirm}</span>}
      </div>

      <div className="text-center">
        <Button type="submit" className="w-100" disabled={disabled}>
          {isLoading ? 'Resetting' : 'Reset Your Password'}
        </Button>
      </div>

    </form>
  );
};

export default LoginResetForm;
