import React from 'react';
import { useToggle } from '../utils/hooks';
import LoginForm from './LoginForm';
import LoginForgotForm from './LoginForgotForm';
import LoginResetForm from './LoginResetForm';

export default props => {
  const [ isForgotActive, toggleForgot ] = useToggle();

  if (props.token) {
    return <LoginResetForm close={props.close} token={props.token} />
  }

  if (true) return (
    <div>
      <h2 className="mb-2">Thanks for the Support</h2>
      <p className="mb-1" style={{fontSize: '0.85em'}}>Discovery Pass is currently inactive. Thank you for supporting local business in Salida and Buena Vista for nearly five years. Please email <a href="mailto:info@discoverypass.co">info@discoverypass.co</a> with any questions.</p>
      <div></div>
    </div>
  )

  return isForgotActive
    ? <LoginForgotForm close={props.close} showLogin={toggleForgot} />
    : <LoginForm close={props.close} showForgot={toggleForgot} onLogin={props.onLogin} />
};
