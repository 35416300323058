import React from 'react';
import { css, cx } from 'emotion';
import { actions } from '../store';
import { colors } from '../styles';
import { isUndefined } from '../utils';
import { useToggle } from '../utils/hooks';
import Icon from './Icon';

const styles = css`
  line-height: 0;
  padding: 0.75em;

  svg {
    width: 22px;
    height: 22px;
    color: ${colors.blueDark};
    stroke-width: 0.75px;
    transform: scale(1);
    transition: all 0.2s var(--ease-out-sine);

    path {
      fill-opacity: 0.3;
      stroke-opacity: 0.9;
    }
  }
  &.is-active svg {
    color: #E63900;
    transform: scale(1.1);

    path {
      fill-opacity: 0.8;
    }
  }
`;

export default ({coupon, className}) => {

  const [ isFavorite, toggleFavorite ] = useToggle(coupon.favorite);

  if (isUndefined(coupon.favorite)) {
    return null;
  }

  function onFavoriteClick(event) {
    event.stopPropagation();
    actions.favorite(coupon.id);
    toggleFavorite();
  }

  return (
    <button className={cx(styles, 'favorite', isFavorite && 'is-active', className)} onClick={onFavoriteClick}>
      <Icon name="heart" />
    </button>
  );
};