import React from 'react';
import { css, cx } from 'emotion';
import { colors } from '../styles';
import Icon from './Icon';

const styles = css`
  display: flex;
  align-items: center;

  a {
    display: inline-block;
    line-height: 0;
    color: currentColor;

    &:hover {
      color: currentColor;
    }
    &.facebook svg {
      width: 24px;
    }
    &.instagram svg {
      width: 25px;
    }
    &.twitter svg {
      width: 26px;
    }
  }
`;

const Social = ({className, ...props}) => (
  <div className={cx(styles, 'social', className)}>
    <a className="facebook" href="https://www.facebook.com/discoverypassco" rel="noopener noreferrer" target="_blank">
      <Icon name="facebook" />
    </a>
    <a className="instagram" href="https://www.instagram.com/discoverypassco/" rel="noopener noreferrer" target="_blank">
      <Icon name="instagram" />
    </a>
    <a className="twitter" href="https://twitter.com/discoverypassco" rel="noopener noreferrer" target="_blank">
      <Icon name="twitter" />
    </a>
  </div>
);

Social.defaultProps = {
  color: colors.orange
};

export default Social;